<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="1.2 * size"
    viewBox="0 0 1200.000000 1000.000000"
  >
    <g
      transform="translate(0.000000,1000.000000) scale(0.100000,-0.100000)"
      :fill="color"
      stroke="none"
    >
      <path
        :fill="color"
        d="M2320 9369 c-462 -54 -858 -388 -985 -835 -32 -114 -848 -3962 -880
        -4154 -151 -894 -61 -1701 258 -2336 132 -261 262 -441 476 -655 192 -192 338
        -301 566 -427 578 -316 1363 -421 2032 -272 922 206 1765 874 2510 1990 343
        515 695 1197 963 1868 96 242 101 258 86 302 -15 48 -70 83 -116 76 -42 -6
        -60 -25 -231 -242 -731 -923 -1506 -1533 -2204 -1733 -504 -145 -993 -89
        -1364 156 -104 69 -258 221 -325 322 -224 336 -296 765 -210 1241 8 47 168
        805 355 1685 188 880 347 1638 355 1685 63 379 -94 799 -393 1055 -249 213
        -569 311 -893 274z"
      />
      <path
        :fill="color"
        d="M8665 9373 c-405 -32 -721 -113 -1075 -276 -171 -79 -421 -230 -593
        -359 -330 -247 -694 -612 -983 -983 -437 -563 -842 -1278 -1188 -2095 -121
        -285 -180 -441 -180 -476 0 -65 67 -121 130 -109 38 7 38 7 252 275 199 249
        348 416 572 641 573 574 1121 931 1644 1069 199 52 285 64 491 64 223 0 329
        -15 506 -74 209 -69 360 -160 504 -305 197 -196 315 -433 372 -745 23 -129 24
        -443 0 -590 -9 -58 -174 -847 -367 -1755 -193 -907 -356 -1686 -362 -1730 -13
        -96 -5 -272 16 -371 48 -223 155 -421 315 -584 170 -171 355 -272 601 -326
        104 -23 327 -23 443 0 429 84 785 408 902 823 26 93 827 3863 869 4093 128
        700 105 1388 -65 1943 -73 237 -154 424 -267 615 -399 673 -1048 1096 -1879
        1222 -205 31 -501 46 -658 33z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "UniteIcon",
  props: {
    color: {
      type: String,
      default: "#ed1f27",
    },
    size: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>
