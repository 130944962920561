import { useAxios, validateSecurity } from "@/utils";
import Flow from "@/controllers/flow";

export default class Configuration {
  constructor(data = {}) {
    this.data = data;
  }

  static async get() {
    if (validateSecurity("configuration")) {
      let res = await useAxios({
        method: "GET",
        url: `/api/configuration`,
        headers: {},
      });
      if (res.success) {
        return parseConfigurations(res.res.data.result.result);
      }
    }

    return [];
  }

  static async update(configurations) {
    if (validateSecurity("configuration", "update")) {
      const res = await useAxios({
        method: "PUT",
        url: `/api/configuration/update`,
        data: {
          data: configurations,
        },
        headers: {},
      });

      return res.success;
    }
  }
  static async getFlows() {
    if (validateSecurity("configuration")) {
      let res = await useAxios({
        method: "GET",
        url: `/api/configuration/flows`,
        headers: {},
      });
      if (res.success) {
        let flows = JSON.parse(res.res.request.response).result
          ? JSON.parse(res.res.request.response).result
          : [];
        flows = flows.map(c => new Flow(c));

        flows.sort((a, b) => {
          let x = a.name.toLowerCase();
          let y = b.name.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }

          return 0;
        });

        return flows;
      }
    }

    return [];
  }
  static async createFlow(flow) {
    if (validateSecurity("configuration")) {
      const res = await useAxios({
        method: "POST",
        url: `/api/configuration/flows`,
        data: flow,
        headers: {},
      });

      if (!res.success) {
        console.log(res.res);
      }

      return res;
    }
  }
  static async updateFlow(flow) {
    if (validateSecurity("configuration")) {
      const res = await useAxios({
        method: "PUT",
        url: `/api/configuration/flows`,
        data: flow,
        headers: {},
      });

      if (!res.success) {
        console.log(res.res);
      }

      return res;
    }
  }
  static async deleteFlow(flow) {
    if (validateSecurity("configuration")) {
      const res = await useAxios({
        method: "DELETE",
        url: `/api/configuration/flows`,
        data: flow,
        headers: {},
      });

      if (!res.success) {
        console.log(res.res);
      }

      return res;
    }
  }

  static async getTestSecret() {
    let res = await useAxios({
      method: "GET",
      url: `/api/me/testSecret`,
      headers: {},
    });
    if (res.success) {
      let result = JSON.parse(res.res.request.response).result
        ? JSON.parse(res.res.request.response).result
        : {};

      return result;
    }

    return [];
  }

  static async addTestFlow(ivr) {
    if (validateSecurity("configuration")) {
      const res = await useAxios({
        method: "POST",
        url: `/api/configuration/flows/test`,
        data: ivr,
        headers: {},
      });

      return res;
    }
  }
  static async removeTestFlow(ivr) {
    if (validateSecurity("configuration")) {
      const res = await useAxios({
        method: "DELETE",
        url: `/api/configuration/flows/test?name=${ivr.name}`,
        headers: {},
      });

      return res;
    }
  }

  static async getVersion() {
    const res = await useAxios({
      method: "GET",
      url: `/api/me/version`,
      headers: {},
    });

    if (res.success) {
      return res.res.data.result;
    } else {
      console.log(res.res);
    }
  }
}

function parseConfigurations(list) {
  let newObject = {};
  list.forEach(element => {
    newObject[element.config] =
      isNaN(element.value) || element.value == ""
        ? element.value
        : Number(element.value);
  });

  return newObject;
}
