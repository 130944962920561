<template>
  <FormBasicLayout
    title="Reset password"
    :showActions="false"
    @back="$emit('close')"
  >
    <template #form>
      <v-form>
        <v-row no-gutters class="pa-3" v-if="fields">
          <v-col
            cols="12"
            class="pb-5"
            v-for="(field, index) in fields"
            :key="index"
          >
            <AutomaticField
              v-model="password[field.value]"
              :field="field"
              light
              class="login--input"
            />
          </v-col>
          <v-col cols="12">
            <v-btn
              block
              rounded
              @click="resetPassword()"
              color="primary"
              class="elevation-0"
            >
              {{ $i18n.t("Confirm") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormBasicLayout>
</template>

<script>
import Auth from "@/controllers/auth.js";

import { getRegexPassword, useAppStore } from "@/utils";

export default {
  data() {
    return {
      password: {
        currentPassword: "",
        newPassword: "",
        confirmation: "",
      },
      show: {
        currentPassword: false,
        newPassword: false,
        confirmation: false,
      },
      fields: null,
    };
  },
  mounted() {
    this.fields = [
      {
        tag: "textfield",
        text: "Current password",
        value: "currentPassword",
        appendIcon: () =>
          this.show.currentPassword ? "mdi-eye" : "mdi-eye-off",
        appendClick: () =>
          (this.show.currentPassword = !this.show.currentPassword),
        placeholder: "Placeholder",
        type: () => (this.show.currentPassword ? "text" : "password"),
      },
      {
        tag: "textfield",
        text: "New password",
        value: "newPassword",
        appendIcon: () => (this.show.newPassword ? "mdi-eye" : "mdi-eye-off"),
        appendClick: () => (this.show.newPassword = !this.show.newPassword),
        placeholder: "Placeholder",
        type: () => (this.show.newPassword ? "text" : "password"),
      },
      {
        tag: "textfield",
        text: "Confirm new password",
        value: "confirmation",
        appendIcon: () => (this.show.confirmation ? "mdi-eye" : "mdi-eye-off"),
        appendClick: () => (this.show.confirmation = !this.show.confirmation),
        placeholder: "Placeholder",
        type: () => (this.show.confirmation ? "text" : "password"),
      },
    ];
  },
  methods: {
    async resetPassword() {
      if (this.password.newPassword == this.password.confirmation) {
        let res = await Auth.resetPassword({
          password: this.password.currentPassword,
          newPassword: this.password.newPassword,
          confirmation: this.password.confirmation,
        });
        if (res.success) {
          this.password.currentPassword = "";
          this.password.newPassword = "";
          this.password.confirmation = "";
          useAppStore().resetPassword(res.res.data.result.newPassword);
          this.$emit("close");
        } else {
          this.$notify({
            group: "app",
            duration: 2000,
            text:
              res.res.response.data.message == "Invalid password."
                ? getRegexPassword(res.res.response.data.param).text
                : res.res.response.data.message,
            title:
              res.res.response.data.message == "Invalid password."
                ? getRegexPassword(res.res.response.data.param)
                : res.res.response.data.message,
          });
        }
      } else {
        this.$notify({
          group: "app",
          duration: 2000,
          text: "The passwords don't match",
        });
      }
    },
  },
};
</script>

<style></style>
