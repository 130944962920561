<template>
  <ReactiveMenu
    @hotkey="openMenu()"
    @open="focusElement()"
    hotkey="alt+n"
    ref="reactiveMenu"
  >
    <template #activator="{ onMenu, attrs }">
      <v-badge
        :content="notifications.length"
        :color="notifications.length ? 'primary' : 'transparent'"
        bottom
        offset-x="20"
        offset-y="15"
      >
        <v-tooltip bottom transition="slide-y-transition">
          <template #activator="{ on: onTooltip }">
            <v-btn
              :disabled="useInboxStore.status === 'Already connected'"
              ref="btnNotifications"
              icon
              v-bind="attrs"
              v-on="{ ...onMenu, ...onTooltip }"
            >
              <v-icon>mdi-bell-outline</v-icon>
            </v-btn>
          </template>

          <span>{{ $i18n.t("Notifications") }}</span>
        </v-tooltip>
      </v-badge>
    </template>
    <template #content>
      <v-card
        :width="$vuetify.breakpoint.smAndDown ? '' : '370px'"
        :elevation="$vuetify.breakpoint.smAndDown ? '0' : ''"
      >
        <v-row no-gutters class="align-center">
          <v-toolbar
            flat
            tile
            :style="{
              background: colorPerTheme(),
            }"
            class="notification-toolbar"
          >
            <v-toolbar-title>
              {{ $i18n.t("Notifications") }}
            </v-toolbar-title>
            <v-btn
              v-if="$vuetify.breakpoint.smAndDown"
              icon
              @click="openMenu()"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar>
          <v-col v-if="notifications.length" cols="12">
            <v-list
              class="pa-0 transparent"
              two-line
              style="max-height: 400px; overflow: auto"
            >
              <v-list-item
                v-for="(item, index) in notifications"
                :key="index"
                class="text--secondary before-background"
                :style="[
                  {
                    'border-bottom':
                      index !== notifications.length - 1
                        ? $vuetify.theme.dark
                          ? 'solid thin rgba(255, 255, 255, 0.12)'
                          : 'solid thin rgba(0, 0, 0, 0.12)'
                        : null,
                  },
                ]"
                @click="item.options ? null : removeNotification(item)"
              >
                <v-list-item-content
                  :style="!$vuetify.theme.dark ? 'color : black' : ''"
                >
                  <span> {{ item.textShow }}</span>
                  <span v-if="item.description" class="mt-2">{{
                    item.description
                  }}</span>

                  <div v-if="item.options" class="mt-3">
                    <v-btn
                      icon
                      :color="option.color"
                      v-for="(option, index) in options.filter(o =>
                        item.options.includes(o.value)
                      )"
                      :key="index"
                      class="pa-3 mr-3"
                      @click.stop="option.method(item)"
                    >
                      <v-icon>
                        {{ option.icon }}
                      </v-icon>
                    </v-btn>
                  </div>
                </v-list-item-content>
                <v-list-item-action
                  class="align-self-start"
                  :style="!$vuetify.theme.dark ? 'color : black' : ''"
                >
                  <Timer
                    :start="luxon.fromFormat(item.date, 'yyyy-MM-dd TT')"
                  />
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" v-if="!notifications.length">
            <BotPlaceholder type="relax" />
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-3 d-flex justify-end" v-if="notifications.length">
          <v-btn @click="removeAllNotification()" icon>
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </v-row>
      </v-card>
    </template>
  </ReactiveMenu>
</template>

<script>
import {
  useInboxStore,
  inbox,
  luxon,
  useAppStore,
  colorPerTheme,
} from "@/utils";
import Timer from "@/components/inbox/interactions/Timer.vue";

import ReactiveMenu from "@/components/base/ReactiveMenu";
import CalendarController from "@/controllers/calendar";

export default {
  name: "Notifications",

  components: {
    Timer,

    ReactiveMenu,
  },

  data() {
    return {
      menu: false,
      useInboxStore: useInboxStore(),
      useAppStore: useAppStore(),
      colorPerTheme,
      luxon: luxon(),
      options: [
        {
          value: "inbox",
          text: this.$i18n.t("Inbox"),
          icon: "mdi-inbox",
          color: "primary",
          method: item => {
            let aux = JSON.parse(item.text);
            let array = [aux.from, aux.to];
            array.sort();

            if (aux.switchAgenda) {
              this.$router.push({
                path: "/redirect/inbox",
                query: { name: array[0] + "|" + array[1] },
              });
              this.$emit("changeRoute");
              this.$emit("changeRoute");
            }
          },
        },
        {
          value: "accept",
          text: this.$i18n.t("Accept"),
          icon: "mdi-check",
          color: "success",
          method: async item => {
            if (JSON.parse(item.text).switchAgenda) {
              let data = JSON.parse(item.text);
              let result = await CalendarController.acceptRequest(data);

              if (result.success) {
                if (data.from == this.useAppStore.user.username) {
                  this.notifications.forEach(n => {
                    if (JSON.parse(n.text).switchAgenda) {
                      this.removeNotification(n);
                    }
                  });
                } else {
                  this.removeNotification(item);
                }
              }
            }
          },
        },
        {
          value: "reject",
          text: this.$i18n.t("Reject"),
          icon: "mdi-close",
          color: "error",
          method: item => {
            this.removeNotification(item);
          },
        },
        {
          value: "download",
          text: this.$i18n.t("Download"),
          icon: "mdi-download",
          color: "primary",
          method: item => this.download(item),
        },
      ],
    };
  },

  computed: {
    notifications() {
      this.useInboxStore.notifications.forEach(n => {
        n.textShow = this.$i18n.t(n.text, n.params);
      });

      return this.useInboxStore.notifications;
    },
  },

  methods: {
    removeNotification(item) {
      if (!inbox().removeNotification(item)) {
        this.$notify({
          group: "app",
          duration: 2000,
          text: "Error",
        });
      }
    },

    removeAllNotification() {
      if (!inbox().removeAllNotification()) {
        this.$notify({
          group: "app",
          duration: 2000,
          text: "Error",
        });
      }
      setTimeout(() => {
        this.closeMenu();
      }, 1000);
    },

    download(item) {
      const filename = item.params.filename;
      const appUrl = window.location.origin;

      const a = document.createElement("a");
      a.href = appUrl + filename;
      a.download = filename;
      a.click();

      this.removeNotification(item);
    },

    openMenu() {
      if (this.$refs.reactiveMenu) {
        this.$refs.reactiveMenu.toggleMenu();
      }
    },
    focusElement() {
      this.$refs.btnNotifications.$el.focus();
      this.$emit("close", "notification");
    },
    closeMenu() {
      if (this.$refs.reactiveMenu) {
        this.$refs.reactiveMenu.closeMenu();
      }
    },
  },
};
</script>
<style>
.before-background:hover::after {
  content: "";
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: var(--v-primary-base) !important;
  opacity: 0.2;
  pointer-events: none;
}

.notification-toolbar .v-toolbar__content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
