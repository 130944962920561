<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="$props.type === 'date' ? getFullDate : date"
        :label="$props.label"
        :disabled="$props.disabled"
        :rules="$props.rules"
        prepend-inner-icon="mdi-calendar-clock"
        validate-on-blur
        readonly
        dense
        v-bind="attrs"
        v-on="on"
        outlined
        hide-details="auto"
        autocomplete="new-password"
      >
        <template #append-outer v-if="$props.info">
          <v-tooltip bottom max-width="200px" color="rgba(0, 0, 0, 0.8)">
            <template #activator="{ on }">
              <v-icon color="info" v-on="on"> mdi-information </v-icon>
            </template>

            <span>{{ $props.info }}</span>
          </v-tooltip>
        </template>
      </v-text-field>
    </template>

    <v-tabs v-model="selectedTab" grow>
      <v-tab
        v-for="(tab, i) in tabs"
        :key="i"
        style="background-color: var(--v-background-accent)"
      >
        <v-icon
          :color="
            selectedTab === i
              ? 'primary'
              : $vuetify.theme.dark
              ? 'white'
              : 'black'
          "
        >
          {{ tab.icon }}
        </v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedTab">
      <v-tab-item>
        <v-card flat class="rounded-0">
          <v-date-picker
            :locale="$i18n.locale"
            :picker-date.sync="date"
            v-model="date"
            color="primary"
            :allowed-dates="allowedDates ? allowedDates() : null"
            full-width
            scrollable
            flat
            :max="$props.maxDate ? $props.maxDate.split(' ')[0] : null"
            :min="$props.minDate ? $props.minDate.split(' ')[0] : null"
            :disabled="$props.disabled"
            header-color="primary"
            :type="$props.type"
            @click:date="selectedTab = 1"
            @change="handleInput"
          />
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat class="rounded-0">
          <v-time-picker
            v-if="menu"
            v-model="time"
            color="primary"
            format="24hr"
            full-width
            scrollable
            flat
            :allowed-hours="allowedHours ? allowedHours : null"
            :allowed-minutes="allowedMinutes ? allowedMinutes : null"
            :max="$props.maxDate ? $props.maxDate.split(' ')[1] : null"
            :min="$props.minDate ? $props.minDate.split(' ')[1] : null"
            :disabled="$props.disabled"
            @click:minute="menu = false"
            @click:hour="handleTime"
            @change="handleTime"
          />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-menu>
</template>

<script>
import { luxon } from "@/utils";

export default {
  name: "DateTimePicker",

  props: {
    label: String,
    maxDate: String,
    minDate: String,
    value: String,
    allowedDates: Function,
    allowedHours: Function,
    allowedMinutes: Function,
    type: String,
    disabled: Boolean,
    rules: Array,
    info: String,
    minTime: Function,
    maxTime: Function,
  },

  data() {
    return {
      date: this.$props.value.split(" ")[0],
      time: this.$props.value.split(" ")[1],
      menu: false,
      selectedTab: 0,
      tabs: [{ icon: "mdi-calendar" }, { icon: "mdi-clock-outline" }],
    };
  },

  watch: {
    menu() {
      this.selectedTab = 0;
    },
  },

  computed: {
    getFullDate() {
      return luxon()
        .fromFormat(this.date + " " + this.time, "yyyy-MM-dd HH:mm:ss")
        .toFormat("yyyy-MM-dd HH:mm:ss");
    },
  },

  methods: {
    handleTime() {
      if (!this.time.split(":")[2]) this.time = this.time + ":00";
      this.handleInput();
    },
    handleInput() {
      this.$emit("input", this.getFullDate);
    },
  },
};
</script>
