<template>
  <v-card
    style="
      width: 100%;
      max-width: 100%;
      box-shadow: 0px 0px 7.95px 0px rgba(0, 0, 0, 0.1) !important;
      border-radius: 16px;
    "
    :style="{
      'background-color': $vuetify.theme.dark
        ? '#242526b3 !important'
        : 'var(--v-background-accent) !important',
    }"
  >
    <v-row no-gutters class="justify-center align-center py-3">
      <!-- Time or name -->
      <v-col
        cols="12"
        class="text-center text-subtitle-2 card-color-label"
        :style="{
          color: $props.disabled ? disabledColor : null,
        }"
      >
        {{
          $props.time == null
            ? $i18n.t($props.channel.text)
            : convertSecondsToTimeFormat($props.time)
        }}
      </v-col>

      <!-- Proggres circular -->
      <v-col cols="12" class="pt-2">
        <v-progress-circular
          :class="!$props.disabled ? `circular--${$props.channel.value}` : null"
          style="width: 100%; height: 100%; padding: 8px !important"
          ref="progressCircular"
          rotate="135"
          width="2"
          :color="$props.disabled ? disabledColor : $props.channel.color"
          rounded
          :value="total != 0 ? ($props.inbound / total) * 100 : 50"
        >
          <template>
            <v-badge bottom offset-x="20" offset-y="20" color="transparent">
              <template #badge>
                <v-icon size="40px"> </v-icon>
              </template>
              <v-icon
                class="pa-2"
                :size="32"
                :color="$props.disabled ? disabledColor : $props.channel.color"
              >
                {{ $props.channel.icon }}
              </v-icon>
            </v-badge>
          </template>
        </v-progress-circular>
      </v-col>

      <!-- Inbound - Outbound or Total -->
      <v-col cols="12" class="pt-3 px-1">
        <v-row no-gutters v-if="$props.time == null">
          <v-col
            :cols="$props.channel.value === 'webchat' ? '12' : '6'"
            class="text-center"
          >
            <p
              class="ma-0 text-caption card-color-label"
              :style="{
                color: $props.disabled ? disabledColor + '!important' : null,
              }"
              style="font-size: 10px !important"
            >
              {{ $i18n.t("Inbound") }}
            </p>
            <p
              class="inbound-value"
              style="font-size: 20px !important"
              :style="{
                color: $props.disabled
                  ? disabledColor + '!important'
                  : $props.channel.color,
              }"
            >
              {{ formatNumber($props.inbound) }}
            </p>
          </v-col>
          <v-col
            cols="6"
            class="text-center"
            v-if="$props.channel.value != 'webchat'"
          >
            <p
              class="ma-0 text-caption card-color-label"
              :style="{
                color: $props.disabled ? disabledColor + '!important' : null,
              }"
              style="font-size: 10px !important"
            >
              {{ $i18n.t("Outbound") }}
            </p>
            <p
              class="outbound-value"
              style="font-size: 20px !important"
              :style="{
                color: $props.disabled
                  ? disabledColor + '!important'
                  : $props.channel.color,
              }"
            >
              {{ formatNumber($props.outbound) }}
            </p>
          </v-col>
        </v-row>

        <v-row v-else class="algin-center">
          <v-col cols="12">
            <p
              class="ma-0 text-caption text-center card-color-label"
              style="font-size: 11px !important"
              :style="{
                color: $props.disabled ? disabledColor + '!important' : null,
              }"
            >
              {{ $i18n.t("Total") }}
            </p>
            <p
              class="inbound-value text-center"
              style="font-size: 20px !important"
              :style="{
                color: $props.disabled
                  ? disabledColor + '!important'
                  : $props.channel.color,
              }"
            >
              {{ formatNumber($props.inbound + $props.outbound) }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { backgroundColor, formatNumber } from "@/utils";

export default {
  props: {
    channel: {
      type: Object,
      default: () => {},
    },
    inbound: {
      type: Number,
      default: 0,
    },
    outbound: {
      type: Number,
      default: 0,
    },
    time: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      backgroundColor,
      formatNumber,
      disabledColor: this.$vuetify.theme.dark ? "#E4E4E4A0" : "#00000060",
    };
  },
  computed: {
    total() {
      return this.$props.inbound + this.$props.outbound;
    },
  },
  methods: {
    convertSecondsToTimeFormat(timeInSeconds) {
      if (!timeInSeconds) return "00:00:00";

      timeInSeconds = (timeInSeconds + "").replace(",", "");
      let hours = Math.floor(timeInSeconds / 3600);
      let minutes = Math.floor((timeInSeconds - hours * 3600) / 60);
      let seconds = timeInSeconds - hours * 3600 - minutes * 60;
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      return hours + ":" + minutes + ":" + seconds;
    },
  },
};
</script>

<style lang="scss">
.outbound-value {
  margin: 0 !important;
  font-weight: bold;
  opacity: 0.4;
}
.inbound-value {
  margin: 0 !important;
  font-weight: bold;
}

.theme--light .card-color-label {
  color: #505253 !important;
}
.theme--dark .card-color-label {
  color: white !important;
}
</style>
