import Highlight from "@tiptap/extension-highlight";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import Document from "@tiptap/extension-document";
import Dropcursor from "@tiptap/extension-dropcursor";
import Image from "@tiptap/extension-image";
import Text from "@tiptap/extension-text";
import Blockquote from "@tiptap/extension-blockquote";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Heading from "@tiptap/extension-heading";
import Paragraph from "@tiptap/extension-paragraph";
import HardBreak from "@tiptap/extension-hard-break";
import BulletList from "@tiptap/extension-bullet-list";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Italic from "@tiptap/extension-italic";
import Bold from "@tiptap/extension-bold";
import Strike from "@tiptap/extension-strike";
import History from "@tiptap/extension-history";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";

export {
  Paragraph,
  Dropcursor,
  Placeholder,
  HardBreak,
  BulletList,
  ListItem,
  OrderedList,
  Italic,
  Bold,
  Strike,
  History,
};

export const CustomDocument = Document.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      documentProperties: {
        parseHTML: element => {
          let attributes = element.getAttributeNames();
          let obj = {};
          attributes.forEach(attribute => {
            obj[attribute] = element.getAttribute(attribute);
          });

          return obj;
        },

        renderHTML: attributes => {
          let attributesKeys = Object.keys(attributes.documentProperties || {});
          let obj = {};
          attributesKeys.forEach(attribute => {
            obj[attribute] = attributes.documentProperties[attribute];
          });

          return obj;
        },
      },
    };
  },
});

export const CustomText = Text;

export const CustomHighlight = Highlight.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      highlightProperties: {
        parseHTML: element => {
          let attributes = element.getAttributeNames();
          let obj = {};
          attributes.forEach(attribute => {
            obj[attribute] = element.getAttribute(attribute);
          });

          return obj;
        },

        renderHTML: attributes => {
          let attributesKeys = Object.keys(
            attributes.highlightProperties || {}
          );
          let obj = {};
          attributesKeys.forEach(attribute => {
            obj[attribute] = attributes.highlightProperties[attribute];
          });

          return obj;
        },
      },
    };
  },
});

export const CustomBlockquote = Blockquote.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      blockquoteProperties: {
        parseHTML: element => {
          let attributes = element.getAttributeNames();
          let obj = {};
          attributes.forEach(attribute => {
            obj[attribute] = element.getAttribute(attribute);
          });

          return obj;
        },

        renderHTML: attributes => {
          let attributesKeys = Object.keys(
            attributes.blockquoteProperties || {}
          );
          let obj = {};
          attributesKeys.forEach(attribute => {
            obj[attribute] = attributes.blockquoteProperties[attribute];
          });

          return obj;
        },
      },
    };
  },
});

export const CustomHorizontalRule = HorizontalRule.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      horizontalRuleProperties: {
        parseHTML: element => {
          let attributes = element.getAttributeNames();
          let obj = {};
          attributes.forEach(attribute => {
            obj[attribute] = element.getAttribute(attribute);
          });

          return obj;
        },
        renderHTML: attributes => {
          let attributesKeys = Object.keys(
            attributes.horizontalRuleProperties || {}
          );
          let obj = {};
          attributesKeys.forEach(attribute => {
            obj[attribute] = attributes.horizontalRuleProperties[attribute];
          });

          return obj;
        },
      },
    };
  },
});

export const CustomTaskItem = TaskItem.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      taskItemProperties: {
        default: false,
        parseHTML: element => {
          let attributes = element.getAttributeNames();
          let obj = {};
          attributes.forEach(attribute => {
            if (attribute == "checked") {
              obj[attribute] = element.getAttribute(attribute) == "true";
            } else {
              obj[attribute] = element.getAttribute(attribute);
            }
          });

          return obj;
        },
        renderHTML: attributes => {
          let attributesKeys = Object.keys(attributes.taskItemProperties || {});
          let obj = {};
          attributesKeys.forEach(attribute => {
            if (attribute == "checked") {
              obj[attribute] = attributes.taskItemProperties[attribute]
                ? "true"
                : "false";
            } else {
              obj[attribute] = attributes.taskItemProperties[attribute];
            }
          });

          return obj;
        },
      },
    };
  },
});

export const CustomTaskList = TaskList.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      taskListProperties: {
        default: false,
        parseHTML: element => {
          let attributes = element.getAttributeNames();
          let obj = {};
          attributes.forEach(attribute => {
            if (attribute == "checked") {
              obj[attribute] = element.getAttribute(attribute) == "true";
            } else {
              obj[attribute] = element.getAttribute(attribute);
            }
          });

          return obj;
        },
        renderHTML: attributes => {
          let attributesKeys = Object.keys(attributes.taskListProperties || {});
          let obj = {};
          attributesKeys.forEach(attribute => {
            if (attribute == "checked") {
              obj[attribute] = attributes.taskListProperties[attribute]
                ? "true"
                : "false";
            } else {
              obj[attribute] = attributes.taskListProperties[attribute];
            }
          });

          return obj;
        },
      },
    };
  },
});

export const CustomTableRow = TableRow.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      rowProperties: {
        parseHTML: element => {
          let attributes = element.getAttributeNames();
          let obj = {};
          attributes.forEach(attribute => {
            obj[attribute] = element.getAttribute(attribute);
          });

          return obj;
        },
        renderHTML: attributes => {
          let attributesKeys = Object.keys(attributes.rowProperties || {});
          let obj = {};
          attributesKeys.forEach(attribute => {
            obj[attribute] = attributes.rowProperties[attribute];
          });

          return obj;
        },
      },
    };
  },
});

export const CustomTableHeader = TableHeader.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      headerProperties: {
        default:
          "min-width: 1em;border: 2px solid #ced4da !important;padding: 3px 5px;vertical-align: top;box-sizing: border-box;position: relative;font-weight: bold;text-align: left;",
        parseHTML: element => element.getAttribute("data-hr-properties"),
        renderHTML: attributes => {
          return {
            style: attributes.headerProperties,
          };
        },
      },
    };
  },
});

export const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      cellProperties: {
        default:
          "min-width: 1em;border: 2px solid #ced4da !important;padding: 3px 5px;vertical-align: top;box-sizing: border-box; position: relative;",
        parseHTML: element => element.getAttribute("data-td-properties"),
        renderHTML: attributes => {
          return {
            style: attributes.cellProperties,
          };
        },
      },
    };
  },
});

export const CustomTable = Table.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      HTMLAttributes: {
        style:
          "border-collapse: collapse; table-layout: fixed;  margin: 0; overflow: hidden",
      },
    };
  },
}).configure({
  resizable: true,
});

export const CustomLink = Link.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      //Links open in new tab by default and go to link no to
      HTMLAttributes: {
        style: "color: #007bff; text-decoration: underline;",
        target: "_blank",
        rel: "noopener noreferrer",
      },

      openOnClick: {
        default: false,
        parseHTML: element => {
          let attributes = element.getAttributeNames();
          let obj = {};
          attributes.forEach(attribute => {
            obj[attribute] = element.getAttribute(attribute);
          });

          return obj;
        },
        renderHTML: attributes => {
          let attributesKeys = Object.keys(attributes.openOnClick || {});
          let obj = {};
          attributesKeys.forEach(attribute => {
            obj[attribute] = attributes.openOnClick[attribute];
          });

          return obj;
        },
      },
    };
  },
});

export const customHeading = Heading.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      level: {
        default: 1,
        parseHTML: element => {
          let attributes = element.getAttributeNames();
          let obj = {};
          attributes.forEach(attribute => {
            obj[attribute] = element.getAttribute(attribute);
          });

          return obj;
        },
        renderHTML: attributes => {
          let attributesKeys = Object.keys(attributes.level || {});
          let obj = {};
          attributesKeys.forEach(attribute => {
            obj[attribute] = attributes.level[attribute];
          });

          return obj;
        },
      },
    };
  },
});

export const customImage = Image.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      imageProperties: {
        parseHTML: element => {
          let attributes = element.getAttributeNames();
          let obj = {};
          attributes.forEach(attribute => {
            if (
              (attribute == "width" || attribute == "height") &&
              obj[attribute] &&
              /\d$/.test(obj[attribute])
            )
              obj[attribute] += "px";
            else obj[attribute] = element.getAttribute(attribute);
          });

          return obj;
        },

        renderHTML: attributes => {
          let attributesKeys = Object.keys(attributes.imageProperties || {});
          let obj = {};
          attributesKeys.forEach(attribute => {
            obj[attribute] = attributes.imageProperties[attribute];
          });

          return obj;
        },
      },
    };
  },
});

export const CustomUnderline = Underline.configure({
  HTMLAttributes: {
    style: "text-decoration: underline",
  },
});

export const CustomTextAlign = TextAlign.configure({
  types: ["heading", "paragraph"],
});

export const CustomTextStyle = TextStyle;

export const CustomColor = Color.configure({
  types: ["textStyle", "heading", "paragraph"],
});
