var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCard),expression:"showCard"}],staticClass:"py-1 timelineItem",attrs:{"elevation":"0","color":"transparent"}},[_c('v-row',{staticClass:"fill-height pb-4",style:({
      color: _vm.$vuetify.theme.dark ? '#fff !important' : '#505253 !important',
    }),attrs:{"no-gutters":""}},[(_vm.$props.showDay)?_c('v-col',{staticClass:"text-center text-body-1 font-weight-bold pb-2",attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.$props.eventDate))])]):_vm._e(),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"4"}},[_c('span',{staticClass:"pa-0 d-flex justify-center text-body-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$props.dateText)+" ")])]),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"8"}},[_c('div',{staticClass:"timeline-card-border use-border rounded-lg",style:({ 'background-color': _vm.getColor + ' !important' })}),_c('div',_vm._l((_vm.eventsComputed),function(events,index){return _c('v-row',{key:index,staticClass:"pl-3",attrs:{"no-gutters":""}},_vm._l((events),function(e,ind){return _c('v-col',{directives:[{name:"show",rawName:"v-show",value:(e.text),expression:"e.text"}],key:ind,staticClass:"py-1 d-flex align-center",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-size":"14px"},style:({
                color: e.color
                  ? e.color.includes('n2p')
                    ? `var(--v-${e.color}-base) !important`
                    : e.color
                  : null,
              })},[_vm._v(" "+_vm._s(_vm.$i18n.te(e.text) ? _vm.$i18n.t(e.text) : e.text)+" ")]),_c('div',{staticClass:"number-information ml-3"},[_c('span',[_vm._v(_vm._s(e.amount))])])])}),1)}),1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }