var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticStyle:{"position":"relative","height":"40px"}},[_c('span',{staticClass:"text-subtitle-1 font-weight-bold",staticStyle:{"position":"absolute","left":"2rem","z-index":"1","top":"0.6rem","pointer-events":"none"},style:(_vm.backgroundWidth < 20 && !_vm.$vuetify.theme.dark
        ? 'color: grey !important;'
        : 'color: white !important;')},[_vm._v(" "+_vm._s(_vm.label + " " + _vm.$attrs.value)+" "+_vm._s(_vm.max == 100 ? "%" : "")+" ")]),_c('v-col',{staticClass:"pa-0",staticStyle:{"width":"100%"},attrs:{"cols":"12"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$attrs.value),expression:"$attrs.value"}],staticStyle:{"width":"100%","height":"40px"},style:('background-image: linear-gradient(to right, var(--v-primary-base) 0%, var(--v-primary-base) ' +
        _vm.backgroundWidth +
        '%, var(--v-background-base) ' +
        _vm.backgroundWidth +
        '% );'),attrs:{"type":"range","min":_vm.min,"max":_vm.max},domProps:{"value":(_vm.$attrs.value)},on:{"input":function($event){return _vm.$emit('input', _vm.$attrs.value)},"__r":function($event){return _vm.$set(_vm.$attrs, "value", $event.target.value)}}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }