var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-avatar',[(_vm.$props.data.avatar && _vm.$props.chat)?_c('UserAvatar',{attrs:{"user":{
        avatar: _vm.$props.data.userAvatar,
        username: _vm.$props.data.subtitle,
        name: _vm.$props.data.title,
      },"status":_vm.$props.data.connected
          ? 'Connected'
          : 'Websocket and Phone not connected',"size":40}}):(_vm.$props.data.resource == 'phonebook')?_c('v-avatar',{staticStyle:{"box-shadow":"0 0 0px 3px var(--v-primary-base) !important"},attrs:{"size":"32"}},[_c('span',[_vm._v(_vm._s(_vm.getInitals(_vm.$props.data.title)))])]):_c('v-avatar',{key:_vm.$props.data.guid,style:([
        {
          'background-color': _vm.$props.data.campaign
            ? _vm.channels.find(e => e.value === 'campaign').color
            : null,
        },
      ]),attrs:{"size":"40"}},[(_vm.$props.data.avatar)?_c('img',{attrs:{"src":_vm.avatarUrl(_vm.$props.data.subtitle, _vm.$props.data.userAvatar)}}):_c('v-icon',{attrs:{"color":_vm.$props.data.campaign ? 'white' : null}},[_vm._v(" "+_vm._s(_vm.$props.data.icon)+" ")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"overflow-x-hidden",attrs:{"cols":"9"}},[_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$props.data.title)+" ")])])],1)],1),(_vm.$props.data.phone)?_c('v-list-item-subtitle',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"overflow-x-hidden",attrs:{"cols":"9"}},[_c('span',{staticClass:"text-caption mr-3"},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-account-outline")]),_vm._v(" "+_vm._s(_vm.$props.data.subtitle)+" ")],1),_c('span',{staticClass:"text-caption"},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-phone-outline")]),_vm._v(" "+_vm._s(_vm.$props.data.phone)+" ")],1)])],1)],1):_vm._e()],1),_c('v-list-item-action',[(_vm.$props.data.phone)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('makeCall', _vm.$props.data)}}},[_c('v-icon',[_vm._v(" mdi-phone-outline ")])],1):_vm._e(),_c('span',{staticClass:"text-right text-caption"},[_vm._v(" "+_vm._s(_vm.$props.data.data)+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }