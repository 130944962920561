<template>
  <div
    :style="
      !$props.loading && !previewFiles.includes(attachmentExtension())
        ? 'max-width: 240px; min-width: 240px; margin: auto 0;'
        : 'max-width: 62px; min-width: 62px'
    "
    class="d-block mx-3 d-flex align-center justify-center pl-2 pb-1"
  >
    <!-- Others files -->
    <v-row
      v-if="!$props.loading && !previewFiles.includes(attachmentExtension())"
      no-gutters
      class="rounded-lg"
      :style="[
        {
          border: $vuetify.theme.dark
            ? 'solid thin rgba(255, 255, 255, 0.12)'
            : 'solid thin rgba(0, 0, 0, 0.12)',
        },
      ]"
    >
      <v-col
        class="d-flex align-center justify-center rounded-l-lg"
        cols="3"
        style="background-color: var(--v-background-base)"
      >
        <v-icon color="white">{{ chipAttachment.icon }}</v-icon>
      </v-col>
      <v-col
        cols="9"
        class="grey rounded-r-lg pa-1 white--text d-flex align-center justify-center"
        style="background-color: var(--v-background-base) !important"
      >
        <v-tooltip top v-if="attachmentName().tooltip">
          <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" style="color: var(--text)">{{
              fileName()
            }}</span>
            <v-btn
              icon
              small
              class="ml-2"
              @click="$emit('remove', $props.data)"
              v-if="input"
            >
              <v-icon color="var(--text)">mdi-close-circle </v-icon>
            </v-btn>
            <v-btn
              icon
              small
              :class="attachmentExtension() == 'mp4' ? '' : 'ml-2'"
              @click="$emit('download')"
              v-if="!input"
              ><v-icon color="var(--text)" small>mdi-download </v-icon></v-btn
            >
            <v-btn
              icon
              small
              class="ml-1"
              @click="$emit('openDialog')"
              v-if="!input && attachmentExtension() == 'mp4'"
              ><v-icon color="var(--text)" small>mdi-eye </v-icon></v-btn
            >
          </template>
          <span>{{ $props.data.name }}</span>
        </v-tooltip>

        <div v-else>
          <span style="color: var(--text)">{{ fileName() }}</span>
          <v-btn
            icon
            small
            class="ml-2"
            @click="removeAttachment($props.data)"
            v-if="input"
            ><v-icon color="white">mdi-close-circle </v-icon></v-btn
          >
          <v-btn
            icon
            small
            :class="attachmentExtension() == 'mp4' ? '' : 'ml-2'"
            @click="$emit('download')"
            v-else
            ><v-icon color="white" small>mdi-download</v-icon></v-btn
          >
          <v-btn
            icon
            small
            class="ml-1"
            @click="$emit('openDialog')"
            v-if="!input && attachmentExtension() == 'mp4'"
            ><v-icon color="white" small>mdi-eye </v-icon></v-btn
          >
        </div>
      </v-col>
    </v-row>

    <!-- Images -->
    <v-tooltip bottom v-else-if="!$props.loading">
      <template #activator="{ on, attrs }">
        <v-hover>
          <template #default="{ hover }">
            <div v-bind="attrs" v-on="on">
              <v-img
                class="rounded-sm"
                :src="$props.data.url"
                style="height: 40px !important; width: 75px"
                :style="[
                  {
                    border: $vuetify.theme.dark
                      ? 'solid thin rgba(255, 255, 255, 0.12)'
                      : 'solid thin rgba(0, 0, 0, 0.12)',
                  },
                ]"
              >
                <v-fade-transition>
                  <v-overlay v-if="hover" absolute color="black">
                    <v-btn
                      icon
                      @click="$emit('remove', $props.data)"
                      v-if="input"
                    >
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                    <v-btn icon @click="$emit('openDialog')" v-else
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </v-overlay>
                </v-fade-transition>
              </v-img>
            </div>
          </template>
        </v-hover>
      </template>

      <span> {{ attachmentName().name }}.{{ attachmentExtension() }} </span>
    </v-tooltip>

    <div
      v-else
      class="rounded-lg d-flex justify-center align-center"
      style="width: 48px; height: 48px"
    >
      <Loader :size="40" />
    </div>
  </div>
</template>

<script>
export default {
  name: "FileChip",

  props: {
    data: {
      type: Object,
    },
    input: { type: Boolean },
    imageChip: { type: Boolean },
    attachmentIcons: { type: Array },
    loading: { type: Boolean },
  },
  data() {
    return {
      previewFiles: ["png", "jpg", "jpeg", "jpeg", "gif", "webp", "svg"],
    };
  },
  computed: {
    chipAttachment() {
      let aux = this.$props.data.name.split(".");
      let type = aux[aux.length - 1];

      return (
        this.$props.attachmentIcons.find(e => e.types.includes(type)) || {
          color: "red",
          icon: "mdi-file",
        }
      );
    },
  },

  methods: {
    attachmentName() {
      let name = this.$props.data.name;
      if (name.length > 10) {
        name = name.substring(0, 10) + "..";
      }

      return {
        name: name,
        tooltip: name.length > 10,
      };
    },
    attachmentExtension() {
      let aux = this.$props.data.name.split(".");

      return aux[aux.length - 1];
    },
    fileName() {
      return this.attachmentName().name;
    },
    removeAttachment(attachment) {
      this.$emit("remove", attachment);
    },
  },
};
</script>
