<template>
  <VueDoughnutChart
    :data="$props.data"
    :options="$props.options"
    :dataset-id-key="$props.datasetIdKey"
    :css-classes="$props.cssClasses"
    :plugins="[$props.options.plugins, centerText]"
    :styles="$props.styles"
    ref="chart"
    v-if="loaded"
  />
</template>

<script>
import { Doughnut as VueDoughnutChart } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { centerText } from "./plugins";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  ChartDataLabels
);

export default {
  name: "DoughnutChart",
  components: {
    VueDoughnutChart,
  },
  props: {
    data: {
      type: Object,
    },
    options: {
      type: Object,
    },
    chartId: {
      type: String,
      default: "doughnut-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },

    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {
        return { height: "100%" };
      },
    },
  },
  data() {
    return {
      loaded: true,
      centerText,
    };
  },

  methods: {
    updateChart() {
      this.loaded = false;
      this.$nextTick(() => {
        this.loaded = true;
      });
    },
  },
};
</script>

<style></style>
