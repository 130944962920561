var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"align-start justify-center fill-height",staticStyle:{"height":"100%","overflow":"hidden"},attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"height":"100% !important"},attrs:{"cols":"12","md":"6"}},[_c('v-row',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleClickOutside),expression:"handleClickOutside"}],staticClass:"align-start justify-center",staticStyle:{"height":"100% !important"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-t-0 rounded-b-lg",class:_vm.$vuetify.breakpoint.smAndDown ? '' : 'pt-1',style:([
            { height: _vm.$vuetify.breakpoint.smAndDown ? '56px' : '64px' },
          ])},[_c('v-card-text',{staticClass:"pa-2"},[_c('v-text-field',{ref:"searchInput",attrs:{"placeholder":_vm.$i18n.t('Search in uContact...'),"prepend-inner-icon":"mdi-magnify","dense":"","outlined":"","rounded":"","hide-details":"auto","autocomplete":"new-password"},on:{"input":function($event){return _vm.handleSearch()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.loading)?_c('loader',{attrs:{"size":24}}):_c('v-icon',{on:{"click":function($event){_vm.$emit('close');
                    _vm.search = null;}}},[_vm._v(" mdi-close ")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-col',{staticClass:"my-3",staticStyle:{"overflow":"auto"},style:([
          {
            height: _vm.getListHeight,
          },
        ]),attrs:{"cols":"12"}},[_c('v-expand-transition',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.search && !_vm.loading && !_vm.writing),expression:"search && !loading && !writing"}],staticClass:"rounded-lg pa-3"},[_c('v-list',{staticClass:"pa-0 overflow-y-auto rounded-lg transparent"},[(!_vm.hasItems)?_c('v-subheader',{staticClass:"primary--text overline"},[_vm._v(_vm._s(_vm.$i18n.t("No results for search")))]):_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((Object.keys(_vm.resources)),function(resource){return _c('div',{key:resource},[(_vm.resources[resource]?.length)?_c('v-subheader',{staticClass:"primary--text overline"},[_vm._v(" "+_vm._s(_vm.$i18n.t(resource))+" ")]):_vm._e(),_vm._l((_vm.resources[resource]),function(item,index){return _c('SearchItem',{key:index,attrs:{"data":{
                      title: item.name,
                      subtitle: item.username,
                      phone: item.phone,
                      avatar: _vm.resourceAvatar[resource],
                      userAvatar: item.avatar,
                      icon: _vm.resourceIcon[resource],
                      resource: resource,
                    }},nativeOn:{"click":function($event){return _vm.handleClick(resource, item)}}})})],2)}),0),_c('v-list-item-group',{attrs:{"color":"primary"}},[(_vm.chats.length)?_c('v-subheader',{staticClass:"primary--text overline"},[_vm._v(_vm._s(_vm.$i18n.t("Chats")))]):_vm._e(),_vm._l((_vm.chats),function(item,index){return _c('SearchItem',{key:index,attrs:{"data":item,"chat":true},on:{"makeCall":_vm.makeCall},nativeOn:{"click":function($event){return _vm.openChat(item)}}})})],2)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }