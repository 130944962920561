import { useAxios, useAppStore, inbox } from "@/utils";
import SimplePeer from "simple-peer";

export class WebRTCController {
  constructor() {
    this.configs = null;
    this.peer = null;
    this.spies = {};
    this.callback = null;
    this.screenStream = null;
    this.createScreenElement();
  }

  async initConfigs() {
    if (this.configs) return;
    let res = await useAxios({
      method: "GET",
      url: `/api/me/webRTC/configs`,
      params: {},
      headers: {},
    });
    if (res.success) {
      const configs = JSON.parse(res.res.request.response).result;
      this.configs = configs;
      if (configs.allowSpy && this.isAgent()) {
        console.log("allowSpy");

        this.getDisplayMedia();
      }
    }
  }

  async getStream() {
    while (!this.screenStream || !this.screenStream.active) {
      await this.getDisplayMedia();
    }
  }

  async getDisplayMedia() {
    if (this.configs.allowSpy) {
      try {
        this.screenStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
        });
        this.screenStream.getVideoTracks()[0].onended = () => {
          this.screenStream = null;
        };
      } catch (ex) {
        this.screenStream = null;
      }
    }
  }

  startScreenSpy(user, callback) {
    if (!this.configs.allowSpy || user == useAppStore().user.username) return;
    if (this.peer) this.stopScreenSpy(user);
    this.callback = callback;
    let video = document.getElementById("screenSpy");
    video.style.display = "block";

    this.peer = this.createSimplePeer(true, user, "startSpy");
  }
  stopScreenSpy() {
    if (!this.peer) return;
    if (this.callback) this.callback();
    this.hideVideo();
    this.peer.destroy();
    this.peer = null;
    this.callback = null;
  }

  async startSpied(supervisor, data) {
    if (!this.spies[supervisor]) {
      console.log("add stream");
      this.spies[supervisor] = this.createSimplePeer(
        false,
        supervisor,
        "spied"
      );
      if (!this.screenStream) await this.getStream();

      this.spies[supervisor].addStream(this.screenStream);
    }

    this.spies[supervisor].signal(data);
  }
  spiedSignal(data) {
    if (!this.peer) return;
    this.peer.signal(data);
  }

  createSimplePeer(initiator, user, type) {
    let iceServers = {};
    let urls = ["stun:stun.l.google.com:19302", "stun:stun.ucontactcloud.com"];
    if (this.configs.ScreenShareEnabled) {
      urls.push("turn:" + this.configs.ScreenShareTurn);
      iceServers.username = this.configs.ScreenShareTurnUser;
      iceServers.credential = this.configs.ScreenShareTurnPass;
    }
    iceServers.urls = urls;

    const peer = new SimplePeer({
      initiator: initiator,
      stream: false,
      config: {
        iceServers: [iceServers],
      },
    });

    peer.on("signal", data => {
      const message = {
        type: type,
        data: data,
        destination: user,
      };
      inbox().sendWsMessage(message);
    });
    peer.on("stream", stream => {
      let video = document.getElementById("screenSpy");
      console.log("video");
      video.srcObject = stream;
    });

    peer.on("close", () => {
      if (initiator) {
        this.peer = null;
        if (this.callback) this.callback();
        this.callback = null;
        this.hideVideo();
      } else this.spies[user] = null;
      console.log(`connection closed`);
    });

    peer.on("error", err => {
      if (initiator) {
        this.peer = null;
        if (this.callback) this.callback();
        this.callback = null;
        this.hideVideo();
      } else this.spies[user] = null;
      console.log(`connection error`);
      console.log(err);
    });

    return peer;
  }

  createScreenElement() {
    const template = document.createElement("template");
    template.innerHTML = this.getScreenElement();
    document.body.appendChild(template.content.firstChild);
  }

  hideVideo() {
    let video = document.getElementById("screenSpy");
    video.srcObject = null;
    video.style.display = "none";
  }

  isAgent() {
    return (
      !useAppStore().user.superuser &&
      !useAppStore().user.licenciator &&
      !useAppStore().profile.controllers.length
    );
  }

  getScreenElement() {
    return `<div style="position: absolute; bottom: 1rem; right: 1rem; z-index: 9999">
      <video
        autoplay
        controls
        style="display: none"
        id="screenSpy"
        width="300px"
        height="300px"
      ></video>
    </div>`.trim();
  }
}
