var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"fill-height card-home shadow-sm",staticStyle:{"border-radius":"18px","min-height":"100%"}},[_c('div',{staticClass:"fill-height pa-2 card-home--wrapper",class:_vm.item.left && _vm.item.right ? '' : 'simple'},[_c('div',{staticClass:"pa-3 card--item card--icon icon"},[_c('div',{staticClass:"d-flex flex-column fill-height align-center",staticStyle:{"width":"100%"}},[_c('v-progress-circular',{ref:"progressCircular",class:`circular--${_vm.item.value}`,staticStyle:{"width":"100%","height":"100%"},attrs:{"rotate":"135","width":"2","color":_vm.item.color(_vm.item.value),"rounded":"","value":_vm.item.total(_vm.item.value) !== 0
              ? _vm.item.invertCalc
                ? (_vm.item.right(_vm.item.value) / _vm.item.total(_vm.item.value)) * 100
                : (_vm.item.left(_vm.item.value) / _vm.item.total(_vm.item.value)) * 100
              : 50}},[[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":_vm.sizes.icon,"color":_vm.item.color(_vm.item.value)}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.item.icon(_vm.item.value))+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t(_vm.item.text(_vm.item.value))))])])]],2),(_vm.showText)?_c('span',{staticStyle:{"color":"var(--text)"}},[_vm._v(" "+_vm._s(_vm.item.text(_vm.item.value))+" ")]):_vm._e()],1)]),(_vm.item.left)?_c('div',{staticClass:"card--item card--text",class:_vm.item.left && _vm.item.right ? 'inbound' : 'data'},[_c('v-row',{staticClass:"text-center fill-height align-content-center",attrs:{"no-gutters":""}},[(_vm.item.left)?_c('v-col',{staticStyle:{"height":"24px"},attrs:{"cols":"12"}},[_c('span',{staticClass:"text-caption",staticStyle:{"color":"var(--text)"}},[_vm._v(" "+_vm._s(_vm.$i18n.t(_vm.item.textLeft))+" ")])]):_vm._e(),_c('v-col',{ref:"containterValues",staticClass:"d-flex align-center justify-center",staticStyle:{"height":"calc(100% - 24px)"},attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-weight":"bolder","text-wrap":"nowrap"},style:({
              color: _vm.item.color(_vm.item.value),
              'font-size': _vm.$vuetify.breakpoint.xlOnly ? '50px' : _vm.sizes.font,
            })},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.item.left(_vm.item.value))))])])],1)],1):_vm._e(),(_vm.item.right)?_c('div',{staticClass:"card--item card--text",class:_vm.item.left && _vm.item.right ? 'outbound' : 'data'},[_c('v-row',{staticClass:"text-center fill-height align-content-center",attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"height":"24px"},attrs:{"cols":"12"}},[_c('span',{staticClass:"text-caption",staticStyle:{"color":"var(--text)"}},[_vm._v(" "+_vm._s(_vm.$i18n.t(_vm.item.textRight))+" ")])]),(_vm.item.right)?_c('v-col',{staticClass:"d-flex align-center justify-center",staticStyle:{"height":"calc(100% - 24px)"},attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-weight":"bolder","opacity":"0.4","text-wrap":"nowrap"},style:({
              color: _vm.item.color(_vm.item.value),
              'font-size': _vm.$vuetify.breakpoint.xlOnly ? '50px' : _vm.sizes.font,
            })},[_vm._v(_vm._s(_vm.formatNumber(_vm.item.right(_vm.item.value))))])]):_vm._e()],1)],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }