<template>
  <v-badge
    bottom
    left
    offset-x="15"
    offset-y="20"
    :color="usePhoneStore.callState == 'onCall' ? 'primary' : 'success'"
  >
    <template #badge>
      <Timer :useServerDate="false" />
    </template>
    <v-btn
      ref="btn"
      icon
      :color="usePhoneStore.callState == 'onCall' ? 'primary' : 'success'"
      @click="answer"
    >
      <v-icon> mdi-phone-outline </v-icon>
    </v-btn>
  </v-badge>
</template>

<script>
import { usePhoneStore } from "@/utils";
import Timer from "@/components/inbox/interactions/Timer.vue";

export default {
  components: { Timer },

  data() {
    return {
      usePhoneStore: usePhoneStore(),
    };
  },

  methods: {
    answer() {
      if (this.usePhoneStore.callState == "ringing") {
        this.usePhoneStore.getPhone().answer();

        setTimeout(() => {
          this.$refs.btn.$el.focus();
        }, 50);
      } else if (this.usePhoneStore.callState == "onCall") {
        this.hangUp();
      }
    },

    hangUp() {
      if (this.usePhoneStore.callState == "onCall") {
        this.usePhoneStore.getPhone().hangUp();
      }
    },
  },
};
</script>
