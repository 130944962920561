<template>
  <v-row no-gutters class="fill-height">
    <v-col cols="12" class="fill-height">
      <v-data-table
        :headers="$props.headers"
        :items="items"
        :items-per-page="-1"
        :loading="$props.loading"
        hide-default-footer
        sort-by="campaign"
        fixed-header
        :style="[
          {
            'max-height': $props.maxHeight,
          },
        ]"
        style="height: calc(100% - 60px); overflow-y: auto"
        class="text--secondary"
        :class="$props.classes"
        :search="$props.search"
      >
        <!-- No data -->
        <template #no-data>
          <slot name="no-data">
            <BotPlaceholder type="parachute" />
          </slot>
        </template>

        <!-- No results -->
        <template #no-results>
          <slot name="no-results">
            <BotPlaceholder type="parachute" />
          </slot>
        </template>

        <template v-for="h in headers" #[getHeaderSlot(h)]="{ header }">
          <slot :name="getHeaderSlot(h)" :header="header">
            {{ $props.translate ? $i18n.t(header.text) : header.text }}
          </slot>
        </template>

        <template v-for="(h, index) in headers" #[getItemSlot(h)]="{ item }">
          <slot :name="getItemSlot(h)" :item="item">
            <v-tooltip bottom v-if="$props.useTooltip">
              <template #activator="{ on }">
                <span v-on="on" class="text">
                  {{ item[h.value] }}
                </span>
              </template>
              <span> {{ item[h.value] }}</span>
            </v-tooltip>
            <span v-else :key="index"> {{ item[h.value] }} </span>
          </slot>
        </template>
        <template #[`item.channel`]="{ item }">
          <v-row no-gutters>
            <v-col cols="2">
              <v-icon :color="item.channel.color" size="16">
                {{ item.channel.icon }}
              </v-icon>
            </v-col>
            <v-col cols="10">
              <span>
                {{ $i18n.t(item.channel.text) }}
              </span>
            </v-col>
          </v-row>
        </template>
        <template #[`item.actions`]="{ item }">
          <ActionsMenu :data="item" :actions="$props.actions" />
        </template>
      </v-data-table>
      <v-row
        no-gutters
        class="align-center justify-end pa-3"
        v-if="!$props.hideFooter"
      >
        <span
          class="overline mr-6"
          v-if="!$vuetify.breakpoint.smAndDown && !$props.hideItemsPerPage"
          style="font-size: 0.75rem !important"
        >
          {{ $i18n.t("Items per page") + ":" }}
        </span>
        <v-select
          v-if="!$props.hideItemsPerPage"
          :items="
            $props.pagination.noPagination
              ? ['all', 5, 10, 15, 20]
              : [5, 10, 15, 20]
          "
          dense
          min="1"
          max="200"
          hide-details="auto"
          outlined
          :value="$props.pagination.itemsPerPage"
          style="max-width: 75px"
          class="mr-6"
          @change="changeItemsPerPage"
        >
        </v-select>
        <span
          class="overline mr-6"
          style="font-size: 0.75rem !important"
          v-if="$props.pagination.itemsPerPage !== 'all'"
        >
          {{
            `${$vuetify.breakpoint.smAndDown ? "" : $i18n.t("Items")} ${
              ($props.pagination.page - 1) * $props.pagination.itemsPerPage + 1
            }-${
              $props.pagination.page * $props.pagination.itemsPerPage
            } ${$i18n.t("of")} ${$props.pagination.totalRecords}`
          }}
        </span>
        <span
          class="overline mr-6"
          style="font-size: 0.75rem !important"
          v-else
        >
          {{
            `${$vuetify.breakpoint.smAndDown ? "" : $i18n.t("Items")} ${
              $props.pagination.totalRecords
            }`
          }}
        </span>
        <v-btn
          icon
          :disabled="$props.pagination.page == 1"
          @click="$emit('previousPage')"
          v-if="$props.pagination.itemsPerPage != 'all'"
        >
          <v-icon> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn
          icon
          :disabled="
            $props.pagination.page == totalPages() ||
            items.length < $props.pagination.itemsPerPage
          "
          @click="$emit('nextPage')"
          v-if="$props.pagination.itemsPerPage != 'all'"
        >
          <v-icon> mdi-chevron-right </v-icon>
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
    },
    pagination: {
      type: Object,
    },
    actions: {
      type: Array,
    },
    data: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    hideForm: {
      type: Boolean,
    },
    maxHeight: {
      type: String,
    },
    classes: {
      type: String,
    },
    search: {
      type: String,
    },
    translate: {
      type: Boolean,
      default: true,
    },
    useTooltip: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
    },
    hideItemsPerPage: {
      type: Boolean,
    },
  },
  computed: {
    items() {
      if (this.$props.pagination.itemsPerPage === "all")
        return this.$props.data;

      let items = [];
      for (let i = 0; i < this.$props.pagination.itemsPerPage; i++) {
        let item =
          this.$props.data[
            (this.$props.pagination.page - 1) *
              this.$props.pagination.itemsPerPage +
              i
          ];
        if (item) items.push(item);
      }

      return items;
    },
  },
  methods: {
    totalPages() {
      return Math.ceil(
        this.$props.pagination.totalRecords /
          this.$props.pagination.itemsPerPage
      );
    },
    changeItemsPerPage(e) {
      this.$emit("changeItemsPerPage", e);
    },
    getHeaderSlot(header) {
      return "header." + header.value;
    },
    getItemSlot(header) {
      return "item." + header.value;
    },
  },
};
</script>

<style>
td:has(> .text) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
</style>
