var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticStyle:{"height":"100%"}},[(_vm.useAppStore().user)?_c('AppMenu',{ref:"AppMenu",attrs:{"crmDomain":_vm.crmDomain},on:{"connectInbox":function($event){return _vm.connectInbox()},"logout":function($event){return _vm.sendBroadcastMessage('logout')}}}):_vm._e(),_c('notifications',{class:[_vm.$vuetify.breakpoint.smAndDown ? 'mt-6 mb-0' : 'mb-6 ml-16'],attrs:{"group":"app","width":"350","position":_vm.$vuetify.breakpoint.smAndDown ? 'top center' : 'bottom left'},scopedSlots:_vm._u([{key:"body",fn:function(props){return [[_c('v-alert',{staticClass:"elevation-4 text-caption mb-3",attrs:{"dense":"","dismissible":"","dark":""},scopedSlots:_vm._u([{key:"close",fn:function({ toggle }){return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":toggle}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$i18n.t(props.item.text, props.item.title))+" ")])]]}}])}),_c('v-main',{staticStyle:{"max-height":"100%"}},[_c('AppContentContainer',{class:[_vm.$route.path != '/inbox' ? 'container-view-tabs mr-5' : ''],staticStyle:{"border-radius":"12px 12px 0 0"}},[_c('router-view',{ref:"main",attrs:{"crmDomain":_vm.crmDomain},on:{"login":_vm.handleLogin,"connectInbox":function($event){return _vm.connectInbox()},"broadcastMessage":_vm.sendBroadcastMessage,"showForm":_vm.showForm,"closeForm":_vm.closeForm,"selectedInteraction":_vm.interactionSelectedChange}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name === 'Inbox' && _vm.inboxConnected),expression:"$route.name === 'Inbox' && inboxConnected"}],staticClass:"nav-forms-container mt-3",class:[
          _vm.drawer.form ? 'show' : 'hide',
          _vm.fullScreenForm ? 'fullscreen' : '',
          _vm.$route.path == '/inbox' && _vm.drawer.form && !_vm.fullScreenForm
            ? 'mr-5'
            : '',
        ]},[_c('FormsContainer',{ref:"formsContainer",attrs:{"forms":_vm.forms},on:{"closeForm":function($event){return _vm.$refs.main.closeFormDrawer()}},model:{value:(_vm.selectedInteraction),callback:function ($$v) {_vm.selectedInteraction=$$v},expression:"selectedInteraction"}})],1)],1)],1),_c('audio',{ref:"selfView",staticStyle:{"display":"none"},attrs:{"src":"","id":"selfView","muted":""}}),_c('audio',{ref:"remoteView",staticStyle:{"display":"none"},attrs:{"src":"","id":"remoteView","autoplay":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }