import { useAxios, useAppStore } from "@/utils";

export default class CRM {
  constructor() {}

  static async login(username, phone) {
    let res = await useAxios({
      method: "POST",
      maxBodyLength: Infinity,
      url: `/api/crm/login`,
      headers: {
        Authorization: "Bearer " + useAppStore().user.token,
      },
      data: {
        user: username,
        phone: phone,
      },
    });
    if (res.success) {
      let result = JSON.parse(res.res.request.response).result
        ? JSON.parse(res.res.request.response).result
        : {};

      return result.data.data;
    } else {
      console.log(res);

      return [];
    }
  }

  static async isEnabled() {
    let res = await useAxios({
      method: "GET",
      url: "/api/crm/isEnabled",
      headers: {
        Authorization: "Bearer " + useAppStore().user.token,
      },
    });
    if (res.success) {
      return res.res.data.result.isEnabled;
    } else {
      return false;
    }
  }

  static async getDomain() {
    let res = await useAxios({
      method: "GET",
      url: "/api/crm/domain",
      headers: {
        Authorization: "Bearer " + useAppStore().user.token,
      },
    });
    if (res.success) {
      return res.res.data.result.domain;
    } else {
      return "";
    }
  }
}
