<template>
  <div
    class="fill-height pa-0 overflow-hidden content_container"
    style="max-height: 100%"
    :style="[
      {
        'border-top':
          $route.fullPath !== '/login'
            ? $vuetify.theme.dark
              ? 'solid thin rgba(255, 255, 255, 0.12)'
              : 'solid thin rgba(0, 0, 0, 0.12)'
            : null,
      },
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "AppContentContainer",
};
</script>
