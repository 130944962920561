import { useAxios, useAppStore, luxon } from "@/utils";

export default class Calendar {
  static async getCampaigns() {
    let res = await useAxios({
      method: "GET",
      url: `/api/calendar/campaigns`,
      headers: {},
    });
    if (res.success) {
      let campaigns = JSON.parse(res.res.request.response).result
        ? JSON.parse(res.res.request.response).result
        : [];

      return campaigns;
    }
  }
  static async updateAgenda(agenda) {
    let res = await useAxios({
      method: "POST",
      url: `/api/calendar/agendas`,
      data: agenda,
      headers: {},
    });
    if (!res.success) {
      console.log(res.res);
    }

    return res;
  }
  static async updateCustomEvent(event, date) {
    let res = await useAxios({
      method: "POST",
      url: `/api/me/event?date=${date}`,
      data: event,
      headers: {},
    });
    if (!res.success) {
      console.log(res.res);
    }

    return res;
  }
  static async uploadBatch(batch) {
    let res = await useAxios({
      method: "POST",
      url: `/api/calendar/batch`,
      data: { batch: batch },
      headers: {},
    });
    if (!res.success) {
      console.log(res.res);
    }

    return res;
  }
  static async getAgendas(campaign, date) {
    let res = await useAxios({
      method: "GET",
      url: `/api/calendar/agendas?campaign=${campaign}&date=${date}`,
      headers: {},
    });
    if (res.success) {
      let agendas = JSON.parse(res.res.request.response).result
        ? JSON.parse(res.res.request.response).result
        : [];
      agendas = agendas.map(c => new Agenda(c));

      return agendas;
    }
  }
  static async getAgentAgendas(start, end) {
    let res = await useAxios({
      method: "GET",
      url: `/api/me/agendas?startDate=${start}${end ? `&endDate=${end}` : ""}`,
      headers: {},
    });
    if (res.success) {
      let agendas = JSON.parse(res.res.request.response).result
        ? JSON.parse(res.res.request.response).result
        : [];
      agendas = agendas.map(c => new Agenda(c));

      return agendas;
    }
  }
  static async getDatesWithAgendas(campaign, startDate, endDate) {
    let res = await useAxios({
      method: "GET",
      url: `/api/calendar/datesWithAgenda?campaign=${campaign}&startDate=${startDate}&endDate=${endDate}`,
      headers: {},
    });
    if (res.success) {
      let dates = JSON.parse(res.res.request.response).result
        ? JSON.parse(res.res.request.response).result
        : [];

      return dates;
    }
  }

  static async getCampaignsMembersInfo() {
    let res = await useAxios({
      method: "GET",
      url: `/api/calendar/agents`,
      headers: {},
    });
    if (res.success) {
      let dates = JSON.parse(res.res.request.response).result
        ? JSON.parse(res.res.request.response).result
        : [];

      return dates;
    }
  }

  static async getRequests() {
    let res;
    if (useAppStore().profile.controllers.find(c => c.name == "campaigns")) {
      res = await useAxios({
        method: "GET",
        url: `/api/calendar/requests`,
        headers: {},
      });
    } else {
      res = await useAxios({
        method: "GET",
        url: `/api/me/requests`,
        headers: {},
      });
    }

    if (res.success) {
      let requests = JSON.parse(res.res.request.response).result
        ? JSON.parse(res.res.request.response).result
        : [];
      requests = requests.map(c => new Request(c));

      return requests;
    }
  }
  static async makeRequest(request) {
    request = new Request(request);
    let statuses = { dayOff: "pending", shiftChange: "inProgress" };
    request.status = statuses[request.type];
    let res = await useAxios({
      method: "POST",
      url: `/api/me/requests`,
      data: request,
      headers: {},
    });
    if (!res.success) {
      console.log(res.res);
    }

    return res;
  }
  static async updateRequest(request) {
    request = new Request(request);
    let res = await useAxios({
      method: "PUT",
      url: `/api/me/requests`,
      data: request,
      headers: {},
    });
    if (!res.success) {
      console.log(res.res);
    }

    return res;
  }
  static async acceptRequest(data) {
    let res = await useAxios({
      method: "PUT",
      url: `/api/me/acceptRequest`,
      data: data,
      headers: {},
    });
    if (!res.success) {
      console.log(res.res);
    }

    return res;
  }

  static async closeRequest(request, finalStatus) {
    request = new Request(request);
    request.status = finalStatus;
    let res = await useAxios({
      method: "PUT",
      url: `/api/me/requests`,
      data: request,
      headers: {},
    });
    if (!res.success) {
      console.log(res.res);
    }

    return res;
  }
}

export class Agenda {
  constructor(data = {}) {
    this.date = data.date || null;
    this.name = data.name || null;
    this.campaign = data.campaign || null;
    this.start = this.truncateTime(data.start) || null;
    this.end = this.truncateTime(data.end) || null;
    if (Object.prototype.toString.call(data.events) == "[object Array]")
      this.events = data.events;
    else if (data.events) this.events = JSON.parse(data.events);
    else this.events = [];
  }

  truncateTime(time) {
    if (!time) return null;
    const timeArray = time.split(":");

    return timeArray[0] + ":" + timeArray[1];
  }
}
export class Request {
  constructor(data = {}) {
    this.id = data.id || null;
    this.campaign = data.campaign || null;
    this.createdDate = data.createdDate || luxon().now().toISO();
    this.date = data.date || null;
    this.description = data.description || null;
    this.agent = data.agent || useAppStore().user.username;
    this.type = data.type || null;

    if (Object.prototype.toString.call(data.data) == "[object Object]")
      this.data = data.data;
    else if (data.data) this.data = JSON.parse(data.data);
    else this.data = {};

    this.status = data.status;
  }
}
