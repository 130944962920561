var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-height pa-0 overflow-hidden content_container",staticStyle:{"max-height":"100%"},style:([
    {
      'border-top':
        _vm.$route.fullPath !== '/login'
          ? _vm.$vuetify.theme.dark
            ? 'solid thin rgba(255, 255, 255, 0.12)'
            : 'solid thin rgba(0, 0, 0, 0.12)'
          : null,
    },
  ])},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }