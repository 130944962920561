import { useAxios, validateSecurity } from "@/utils";

export default class Phonebook {
  constructor(data = {}) {
    if (!data) data = {};

    this.id = data.id || null;
    this.name = data.name || null;
    if (Object.prototype.toString.call(data.emails) == "[object Array]")
      this.emails = data.emails;
    else if (data.emails) this.emails = JSON.parse(data.emails);
    else this.emails = [];

    if (Object.prototype.toString.call(data.phones) == "[object Array]")
      this.phones = data.phones;
    else if (data.phones) this.phones = JSON.parse(data.phones);
    else this.phones = [];

    this.source = data.source || null;

    if (Object.prototype.toString.call(data.favorites) == "[object Array]")
      this.favorites = data.favorites;
    else if (data.favorites) this.favorites = JSON.parse(data.favorites);
    else this.favorites = [];
  }
  static async get() {
    if (validateSecurity("contacts")) {
      let res = await useAxios({
        method: "GET",
        url: `/api/phonebook`,
        headers: {},
      });
      if (res.success) {
        let phonebook = JSON.parse(res.res.request.response).result
          ? JSON.parse(res.res.request.response).result
          : [];
        phonebook = phonebook.map(c => {
          return new Phonebook(c);
        });

        phonebook.sort((a, b) => {
          let x = a.name.toLowerCase();
          let y = b.name.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }

          return 0;
        });

        return phonebook;
      }
    }

    return [];
  }

  static async create(phonebook) {
    if (validateSecurity("contacts", "create")) {
      const res = await useAxios({
        method: "POST",
        url: `/api/phonebook/id/${phonebook.name}`,
        data: phonebook,
        headers: {},
      });

      if (!res.success) {
        console.log(res.res);
      }

      return res;
    }
  }
  static async update(phonebook) {
    if (validateSecurity("contacts", "update")) {
      const res = await useAxios({
        method: "PUT",
        url: `/api/phonebook/id/${phonebook.name}`,
        data: phonebook,
        headers: {},
      });

      if (!res.success) {
        console.log(res.res);
      }

      return res;
    }
  }
  static async delete(phonebook) {
    if (validateSecurity("contacts", "delete")) {
      const res = await useAxios({
        method: "DELETE",
        url: `/api/phonebook/id/${phonebook.name}`,
        data: phonebook,
        headers: {},
      });

      if (!res.success) {
        console.log(res.res);
      }

      return res;
    }
  }

  static async createAgent(phonebook) {
    const res = await useAxios({
      method: "POST",
      url: `/api/me/phonebook`,
      data: phonebook,
      headers: {},
    });

    if (!res.success) {
      console.log(res.res);
    }

    return res;
  }
  static async updateAgent(phonebook) {
    const res = await useAxios({
      method: "PUT",
      url: `/api/me/phonebook`,
      data: phonebook,
      headers: {},
    });

    if (!res.success) {
      console.log(res.res);
    }

    return res;
  }
  static async deleteAgent(phonebook) {
    const res = await useAxios({
      method: "DELETE",
      url: `/api/me/phonebook`,
      data: phonebook,
      headers: {},
    });

    if (!res.success) {
      console.log(res.res);
    }

    return res;
  }

  static async batch(batch) {
    const res = await useAxios({
      method: "POST",
      url: `/api/phonebook/batch`,
      data: {
        batch: batch,
      },
      headers: {},
    });

    if (!res.success) {
      console.log(res.res);
    }

    return res;
  }
  static async favorite(phonebook) {
    const res = await useAxios({
      method: "PUT",
      url: `/api/me/phonebook`,
      data: phonebook,
      headers: {},
    });

    if (!res.success) {
      console.log(res.res);
    }

    return res;
  }
}
