<template>
  <v-card
    class="fill-height FormsTabsLayout"
    :class="[size, flex ? 'd-flex' : null]"
    :max-height="$vuetify.breakpoint.smAndDown ? null : $props.height"
  >
    <v-row no-gutters :class="flex ? 'align-start' : 'fill-height'">
      <v-col
        cols="12"
        md="3"
        class="grayTabs"
        :class="[
          $vuetify.breakpoint.smAndDown ? null : 'fill-height',
          $vuetify.theme.dark ? 'dark' : '',
        ]"
      >
        <!-- Title -->
        <v-card-title
          class="text-h6 text--primary pl-10"
          :class="[{ 'justify-center': $vuetify.breakpoint.smAndDown }]"
          style="color: var(--v-primary-base) !important; font-weight: bold"
        >
          {{ $i18n.t($props.title) }}
        </v-card-title>

        <v-btn
          v-if="!$props.showActions && $vuetify.breakpoint.smAndDown"
          icon
          x-large
          class="mr-2"
          style="position: absolute; top: 0; right: 0"
          @click="$emit('back')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <!-- Tabs -->
        <slot name="tabs" />
      </v-col>

      <v-col
        cols="12"
        md="9"
        class="fill-height"
        :style="[
          {
            'max-height': $vuetify.breakpoint.smAndDown
              ? 'calc(100% - 136px) !important'
              : null,
          },
        ]"
        style="background-color: var(--v-background-accent)"
      >
        <v-card-text class="pa-5 fill-height">
          <!-- Form -->
          <div
            :class="overflowY ? null : 'overflow-y-auto'"
            :style="[
              {
                height: $props.showActions
                  ? 'calc(100% - 52px) !important'
                  : '100%',
              },
            ]"
          >
            <slot name="form" />
          </div>

          <!-- Actions -->
          <v-card-actions v-if="$props.showActions">
            <v-row no-gutters class="d-flex justify-center">
              <v-col :cols="changeButtonSize" class="px-3">
                <v-btn
                  block
                  rounded
                  class="elevation-0"
                  :color="
                    $vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-2'
                  "
                  @click="$emit('back')"
                >
                  {{ $i18n.t("Cancel") }}
                </v-btn>
              </v-col>
              <v-col :cols="changeButtonSize" class="px-3">
                <v-btn
                  class="elevation-0"
                  block
                  rounded
                  color="primary"
                  :loading="$props.loading"
                  :disabled="$props.disabled"
                  @click="$emit('continue')"
                >
                  {{ $i18n.t("Save") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "FormTabsLayout",

  props: {
    title: { type: String },
    height: { type: String },
    loading: { type: Boolean },
    disabled: { type: Boolean },
    showActions: { type: Boolean },
    flex: { type: Boolean, default: false },
    overflowY: { type: Boolean, default: false },
    size: {
      type: String,
      default: "md",
      validator: value => ["sm", "md", "lg", "xl"].includes(value),
    },
  },
  computed: {
    changeButtonSize() {
      let validatorSize = ["sm", "md", "lg", "xl"];
      let buttonsSizes = [6, 6, 5, 4];

      let iArray = -1;

      validatorSize.forEach((e, index) => {
        if (e === this.size) {
          iArray = index;
        }
      });

      return buttonsSizes[iArray];
    },
  },
};
</script>

<style>
.v-tabs--vertical > .v-tabs-bar .v-tabs-slider {
  height: 0 !important;
}
.grayTabs,
.grayTabs .v-tab {
  background: var(--v-background-base) !important;
}
</style>
<style scoped>
.small {
  height: 400px;
}

.medium {
  height: 600px;
}

.large {
  height: 850px;
}
</style>

<style>
.v-dialog:has(.FormsTabsLayout) .v-slide-group__content div {
  padding-left: 25px;
}
.v-dialog:has(.FormsTabsLayout.sm):not(.v-dialog--fullscreen) {
  height: 35% !important;
  width: auto;
  aspect-ratio: 16 / 9;
}
.v-dialog:has(.FormsTabsLayout.md):not(.v-dialog--fullscreen) {
  height: 50% !important;
  width: auto;

  aspect-ratio: 16 / 9;
}

.v-dialog:has(.FormsTabsLayout.lg):not(.v-dialog--fullscreen) {
  height: 65% !important;
  width: auto;

  aspect-ratio: 16 / 9;
}

.v-dialog:has(.FormsTabsLayout.xl):not(.v-dialog--fullscreen) {
  height: 90% !important;
  width: auto;
  aspect-ratio: 16 / 9;
}
</style>
