<template>
  <div
    style="width: 100%; height: 100%; border-radius: inherit; overflow: hidden"
  >
    <FormContainer
      v-for="form in forms"
      :key="form.guid"
      :ref="`form-${form.guid}`"
      :data="form"
      @close="$emit('closeForm')"
      v-show="value === form.guid"
    />
  </div>
</template>

<script>
import FormContainer from "@/components/inbox/FormContainer";

export default {
  name: "FormsContainer",

  components: {
    FormContainer,
  },

  props: {
    forms: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      id: Date.now(),
    };
  },

  methods: {},
};
</script>
