export default class WindowProxy {
  static sendProxyEvent(event, data) {
    window.parent.postMessage(
      {
        event: event,
        data: data,
      },
      "*"
    );

    const iframes = ["crm-iframe", "form-iframe"];

    iframes.forEach(iframe => {
      const iframeElement = document.getElementById(iframe);
      if (iframeElement) {
        iframeElement.contentWindow.postMessage(
          {
            event: event,
            data: data,
          },
          "*"
        );
      }
    });
  }

  static assignedInteraction(data) {
    let info = {
      guid: data.guid,
      campaign: data.campaign,
      channel: data.channel,
      clientId: data.clientId,
      contactId: data.contactId,
    };
    this.sendProxyEvent("assignedInteraction", info);
  }

  static finishInteraction(guid, data) {
    let info = {
      guid: guid,
      finishDate: data.finishDate,
    };
    this.sendProxyEvent("finishedInteraction", info);
  }

  static sendDisposition(guid, levels, comment) {
    let data = {
      guid: guid,
      levelsId: levels.map(l => l.id),
      levelsValue: levels.map(l => l.value),
      comment: comment,
    };
    this.sendProxyEvent("sentDisposition", data);
  }

  static changeTheme(theme) {
    let data = {
      dark: theme.isDark,
      primary: theme.currentTheme.primary,
    };

    this.sendProxyEvent("changedTheme", data);
  }

  static changeLanguage(lang) {
    this.sendProxyEvent("changedLanguage", lang);
  }

  static toggleSubmenu() {
    this.sendProxyEvent("toggleSubmenu", {});
  }

  static toggleFullscreen(status) {
    this.sendProxyEvent("toggleFullscreen", { origin: "ucx", status });
  }

  static sendInteraction(interaction) {
    if (!interaction) return;
    const iframe = document.getElementById("form-iframe-" + interaction.guid);
    if (iframe)
      iframe.contentWindow.postMessage(
        {
          action: "interaction",
          interaction: interaction,
        },
        "*"
      );
  }
}

export const init = () => {
  console.log("init proxy");
  window.addEventListener("message", e => {
    const { event, data } = e.data;

    if (event) {
      window.dispatchEvent(
        new CustomEvent(event, {
          detail: data,
        })
      );
    }
  });
};
