<template>
  <div class="auto-suggest-container">
    <div class="d-flex" style="width: 100%; position: relative">
      <v-textarea
        :disabled="$props.disabled"
        auto-grow
        single-line
        :rows="1 + Number(nextRow)"
        :readonly="$props.readonly"
        v-model="textModel"
        type="text"
        :label="$i18n.t($props.label)"
        :rules="$props.rules || []"
        dense
        hide-details="auto"
        outlined
        @keydown.down="incrementOffset()"
        @keydown.up="decrementOffset()"
        @keydown.enter.stop.prevent="completeSuggestion"
        @input="manageInput"
        autocomplete="new-password"
      >
      </v-textarea>
      <!--Placeholder-->
      <span class="placeholder" v-show="suggestion">{{ suggestion }}</span>
    </div>
    <span
      style="opacity: 0.6"
      class="text-caption text--disabled"
      v-show="isFocused && suggestionsFiltered.length > 0"
    >
      {{ offset + 1 }}/{{ suggestionsFiltered.length }}
    </span>
    <input disabled style="display: none" type="text" :value="valueee" />
  </div>
</template>

<script>
function generateRegex(delimiter) {
  // Escape special characters in the regular expression
  const escapedDelimiter = delimiter.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  // Create the regular expression with the provided delimiter
  const regexString = `([${escapedDelimiter}]+)`;

  // Create and return the regular expression
  return new RegExp(regexString, "g");
}

export default {
  name: "AutoSuggest",

  props: {
    label: {
      type: String,
    },
    suggestions: {
      type: Array,
      required: true,
    },

    suggestionText: {
      type: String,
    },

    suggestionValue: {
      type: String,
    },

    disabled: {
      type: [Boolean, Function],
      default: false,
    },

    readonly: {
      type: [Boolean, Function],
      default: false,
    },

    splitter: {
      type: [String, RegExp],
      default: ",",
    },
    rules: {
      type: Array,
    },
    noTranslate: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
  },

  data() {
    return {
      valid: true,
      isFocused: true,
      splitterAsRegExp:
        this.splitter instanceof RegExp
          ? this.splitter
          : generateRegex(this.splitter),
      offset: 0,
      valueee: this.value || "",
    };
  },

  methods: {
    manageInput() {
      this.addSuggestion();
      this.resetOffset();
    },
    addSuggestion() {
      let val = this.valueee;

      let words = val.split(this.splitterAsRegExp);
      let newValue = [];
      for (let i = 0; i < words.length; i++) {
        let word = words[i];
        let suggestion = this.suggestions.find(item =>
          this.$props.noTranslate
            ? item[this.suggestionText] === word
            : this.$i18n.t(item[this.suggestionText]) === word
        );
        if (suggestion) {
          newValue.push(suggestion[this.suggestionValue]);
        } else {
          newValue.push(word);
        }
      }
      this.valueee = newValue.join("");
      this.$emit("input", this.valueee);
    },

    completeSuggestion() {
      let val = this.valueee.split(this.splitterAsRegExp);
      let filtered = this.suggestionsFiltered;
      let lastWord = val.pop();
      let suggestion = filtered[this.offset];
      if (suggestion) {
        val.push(suggestion[this.suggestionValue]);
      } else {
        val.push(lastWord);
      }
      this.valueee = val.join("");
      this.$emit("input", this.valueee);
    },

    resetOffset(e) {
      if (e && e.keyCode >= 37 && e.keyCode <= 40) return;
      this.offset = 0;
    },

    incrementOffset() {
      const suggestions = this.suggestionsFiltered;
      if (suggestions.length > 0) {
        this.offset =
          this.offset + 1 < suggestions.length
            ? this.offset + 1
            : suggestions.length - 1;
      }
    },

    decrementOffset() {
      if (this.suggestionsFiltered.length) {
        this.offset = this.offset - 1 >= 0 ? this.offset - 1 : 0;
      }
    },
  },

  computed: {
    suggestionsFiltered() {
      if (!this.textModel) return [];

      //if the last word is the splitter, return empty array
      if (
        this.textModel.split(this.splitterAsRegExp).pop().trim() === "" ||
        this.splitterAsRegExp.test(
          this.textModel.split(this.splitterAsRegExp).pop().trim()
        )
      )
        return [];

      let lastWord = this.textModel
        .split(this.splitterAsRegExp)
        .pop()
        .toLowerCase();
      let suggestions = this.suggestions.filter(item =>
        this.$props.noTranslate
          ? item[this.suggestionText].startsWith(lastWord)
          : this.$i18n
              .t(item[this.suggestionText])
              .toLowerCase()
              .startsWith(lastWord)
      );

      return suggestions;
    },

    suggestion() {
      const suggestions = this.suggestionsFiltered;
      if (!this.isFocused) return "";
      if (suggestions && suggestions[this.offset]) {
        //Replace the last word with the suggestion
        let lastWord = this.textModel.split(this.splitterAsRegExp);
        let suggestion = this.$props.noTranslate
          ? suggestions[this.offset][this.suggestionText]
          : this.$i18n.t(suggestions[this.offset][this.suggestionText]);

        return (
          this.textModel.slice(
            0,
            this.textModel.length - lastWord.pop().length
          ) + suggestion
        );
      }

      return "";
    },

    textModel: {
      get() {
        let val = this.valueee;

        let words = val.split(this.splitterAsRegExp);
        let newValue = [];
        for (let i = 0; i < words.length; i++) {
          let word = words[i];
          let suggestion = this.suggestions.find(
            item => item[this.suggestionValue] === word
          );
          if (suggestion) {
            newValue.push(
              this.$props.noTranslate
                ? suggestion[this.suggestionText]
                : this.$i18n.t(suggestion[this.suggestionText])
            );
          } else {
            newValue.push(word);
          }
        }

        return newValue.join("");
      },
      set(value) {
        this.valueee = value;
        this.$emit("input", value);
      },
    },

    nextRow() {
      return this.textModel.length > 55;
    },
  },
};
</script>

<style lang="scss" scoped>
.auto-suggest-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.auto-suggest {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  background-color: var(--v-background-base);
  color: var(--v-text-base);
}

.auto-suggest:focus {
  border: 1px solid var(--v-primary-base);
}

.placeholder {
  position: absolute;
  margin-top: 6px;
  top: 0;
  left: 12px;
  font-size: 16px;
  opacity: 0.6;
  pointer-events: none;
  transition: 0.3s ease-out;
  align-self: stretch;
  flex: 1 1 auto;
  line-height: 1.75rem;
  min-height: 32px;
  outline: none;
  padding: 0px;
  width: calc(100% - 12px);
  padding-right: 12px;
  letter-spacing: normal;
  height: calc(100% - 6px);
  overflow: hidden;
}
</style>
