var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{style:([
    {
      'margin-left': _vm.$vuetify.breakpoint.mdAndDown ? null : '50px',
    },
  ]),attrs:{"app":true,"clipped-left":"","flat":""}},[_c('v-row',{staticClass:"align-center fill-height",attrs:{"no-gutters":""}},[(_vm.$vuetify.breakpoint.mdAndDown && _vm.navigation)?_c('v-btn',{attrs:{"icon":"","disabled":_vm.$props.disableMenu},on:{"click":function($event){return _vm.$emit('openMenu')}}},[_c('v-icon',[_vm._v("mdi-menu")])],1):_vm._e(),_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require(`@/assets/logo/uContact_${
          _vm.$vuetify.breakpoint.smAndDown ? 'iso_light' : 'light'
        }.svg`),"contain":"","max-width":_vm.$vuetify.breakpoint.smAndDown ? 40 : 150,"max-height":"100%"},on:{"click":function($event){return _vm.$emit('home')}}}),_c('v-btn',{directives:[{name:"hotkey",rawName:"v-hotkey",value:({
        'alt+s': () => {
          _vm.openSearch();
        },
        'alt+a': () => {
          _vm.phoneAnswer();
        },
        'alt+h': () => {
          _vm.phoneHangUp();
        },
      }),expression:"{\n        'alt+s': () => {\n          openSearch();\n        },\n        'alt+a': () => {\n          phoneAnswer();\n        },\n        'alt+h': () => {\n          phoneHangUp();\n        },\n      }"}],attrs:{"icon":""},on:{"click":_vm.openSearch}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-dialog',{attrs:{"content-class":"searchDialog","transition":"dialog-top-transition"},model:{value:(_vm.dialogs.search),callback:function ($$v) {_vm.$set(_vm.dialogs, "search", $$v)},expression:"dialogs.search"}},[_c('SearchBar',{ref:"searchBar",attrs:{"open":_vm.searchOpen},on:{"close":function($event){_vm.dialogs.search = false;
          _vm.searchOpen = false;},"changeRoute":function($event){return _vm.$emit('changeRoute')}}})],1),_c('v-spacer'),_c('div',{staticClass:"justify-end d-flex"},[(
          _vm.usePhoneStore.callState === 'ringing' ||
          _vm.usePhoneStore.callState === 'onCall'
        )?_c('PhoneAnswerButton',{ref:"btnPhoneAnswer",on:{"changeRoute":function($event){return _vm.$emit('changeRoute')}}}):_vm._e(),(_vm.useInboxStore.wrapup)?_c('WrapupButton',{staticClass:"ml-6"}):_vm._e(),_c('Breaks',{ref:"statusesMenu",on:{"close":_vm.closeMenu}}),_c('Notifications',{ref:"notificationsMenu",on:{"close":_vm.closeMenu,"changeRoute":function($event){return _vm.$emit('changeRoute')}}}),_c('UserMenu',{ref:"userMenu",on:{"connectInbox":function($event){return _vm.$emit('connectInbox')},"close":_vm.closeMenu,"logout":function($event){return _vm.$emit('logout')}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }