import Vue from "vue";
import VueRouter from "vue-router";
import { useInboxStore, useAppStore, inbox } from "@/utils";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/views/Home.vue"),
    meta: {
      requiresAuth: true,
      isAuthValid: () => {
        return true;
      },
    },
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: {
      requiresAuth: true,
      isAuthValid: () => {
        return true;
      },
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: {
      requiresAuth: false,
      isAuthValid: () => {
        return true;
      },
    },
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("@/views/Users.vue"),
    meta: {
      requiresAuth: true,
      isAuthValid: resources => {
        return (
          resources.find(resource => resource.name == "users") ||
          resources.find(resource => resource.name == "profiles")
        );
      },
    },
  },
  {
    path: "/campaigns",
    name: "Campaigns",
    component: () => import("@/views/Campaigns.vue"),
    meta: {
      requiresAuth: true,
      isAuthValid: resources => {
        return resources.find(resource => resource.name == "campaigns");
      },
    },
  },
  {
    path: "/connectors",
    name: "Connectors",
    component: () => import("@/views/Connectors.vue"),
    meta: {
      requiresAuth: true,
      isAuthValid: resources => {
        return resources.find(resource => resource.name == "connectors");
      },
    },
  },
  {
    path: "/configuration",
    name: "Configuration",
    component: () => import("@/views/Configuration.vue"),
    meta: {
      requiresAuth: true,
      isAuthValid: resources => {
        return resources.find(resource => resource.name == "configuration");
      },
    },
  },
  {
    path: "/automations",
    name: "Automations",
    component: () => import("@/views/Automations.vue"),
    meta: {
      requiresAuth: true,
      isAuthValid: () => {
        return true;
      },
    },
  },
  {
    path: "/inbox",
    name: "Inbox",
    component: () => import("@/views/Inbox.vue"),
    meta: {
      requiresAuth: true,
      isAuthValid: () => {
        return true;
      },
    },
  },
  {
    path: "/analyticsUsers",
    name: "AnalyticsUsers",
    component: () => import("@/views/AnalyticsUsers.vue"),
    meta: {
      requiresAuth: true,
      isAuthValid: resources => {
        return resources.find(resource => resource.name == "users");
      },
    },
  },
  {
    path: "/analyticsCampaigns",
    name: "AnalyticsCampaigns",
    component: () => import("@/views/AnalyticsCampaigns.vue"),
    meta: {
      requiresAuth: true,
      isAuthValid: resources => {
        return resources.find(resource => resource.name == "campaigns");
      },
    },
  },
  {
    path: "/analyticsConnectors",
    name: "AnalyticsConnectors",
    component: () => import("@/views/AnalyticsConnectors.vue"),
    meta: {
      requiresAuth: true,
      isAuthValid: resources => {
        return resources.find(resource => resource.name == "connectors");
      },
    },
  },
  {
    path: "/analyticsOutboundHub",
    name: "Dialers",
    component: () => import("@/views/AnalyticsDialers.vue"),
    meta: {
      requiresAuth: true,
      isAuthValid: resources => {
        return resources.find(resource => resource.name == "dialers");
      },
    },
  },
  {
    path: "/forms",
    name: "Forms",
    component: () => import("@/views/Forms.vue"),
    meta: {
      requiresAuth: true,
      isAuthValid: resources => {
        return resources.find(resource => resource.name == "forms");
      },
    },
  },
  {
    path: "/wallboards",
    name: "Wallboards",
    component: () => import("@/views/Wallboards.vue"),
    meta: {
      requiresAuth: true,
      isAuthValid: resources => {
        return resources.find(resource => resource.name == "wallboards");
      },
    },
  },
  {
    path: "/interactions",
    name: "Interactions",
    component: () => import("@/views/Interactions.vue"),
    meta: {
      requiresAuth: true,
      isAuthValid: resources => {
        return resources.find(resource => resource.name == "campaigns");
      },
    },
  },
  {
    path: "/interaction/:id",
    name: "Interaction",
    component: () => import("@/views/Interaction.vue"),
    meta: {
      requiresAuth: true,
      isAuthValid: () => {
        return true;
      },
    },
  },

  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   component: () => import("@/views/Calendar.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     isAuthValid: () => {
  //       return true;
  //     },
  //   },
  // },
  {
    path: "/crm",
    name: "CRM",
    component: () => import("@/views/CRM.vue"),
    props: true,
    meta: {
      requiresAuth: true,
      isAuthValid: () => {
        return true;
      },
    },
  },
  {
    path: "/changePassword",
    name: "ChangePassword",
    component: () => import("@/views/ChangePassword.vue"),
    meta: {
      requiresAuth: false,
      isAuthValid: () => {
        return true;
      },
    },
  },
  {
    path: "/redirect/:route/:parameter?",
    name: "redirect",
    component: () => import("@/components/base/Redirect.vue"),
    meta: {
      requiresAuth: true,
      isAuthValid: () => {
        return true;
      },
    },
  },
  {
    path: "/notAllowed",
    component: () => import("@/views/NotAllowed.vue"),
    meta: {
      requiresAuth: true,
      isAuthValid: () => {
        return true;
      },
    },
  },
  {
    path: "*",
    component: () => import("@/views/NotFound.vue"),
    meta: {
      requiresAuth: true,
      isAuthValid: () => {
        return true;
      },
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAgent =
    useAppStore().user &&
    !useAppStore().user.superuser &&
    useAppStore().profile &&
    !useAppStore().profile.controllers.length;

  let ignoreUrl =
    to.name !== "Login" &&
    to.name !== "ChangePassword" &&
    to.path !== "/redirect/changePassword";

  if (!document.cookie.includes("ucToken") && ignoreUrl) {
    useAppStore().clear();
    useInboxStore().clear();
    next("/login");

    return;
  }

  if (!useAppStore().user && !useAppStore().profile && ignoreUrl) {
    //If you are not logged
    next("/login");
  } else if (
    //If you are logged in and you try to access a page that requires authentication, you will be redirected to the login page
    to.meta.requiresAuth &&
    useAppStore().user &&
    useAppStore().user.superuser === 0 &&
    useAppStore().profile &&
    !to.meta.isAuthValid(useAppStore().profile.controllers)
  ) {
    next("/notAllowed");
  } else if (
    //If you are logged in and you try to access the login page, you will be redirected to the home page
    useAppStore().user &&
    (to.name === "Login" ||
      to.name === "ChangePassword" ||
      to.path === "/redirect/changePassword")
  ) {
    next(useAppStore().profile.controllers.length ? "/" : "/inbox");
  } else {
    //If you are logged in and you have the authorization to access the page, you will be redirected to the page
    if (to.name !== "Inbox" && useInboxStore().openInteraction) {
      inbox().readMessages(useInboxStore().openInteraction);
      useInboxStore().setOpenInteraction(null);
    }

    //If you are an agent and try to get to a page that isn't the inbox or calendar you are redirected to the inbox
    if (isAgent && to.name !== "Inbox" && to.name !== "Calendar")
      next("/inbox");
    else next();
  }
});

export default router;
