<template>
  <v-card
    elevation="0"
    color="transparent"
    class="py-1 timelineItem"
    v-show="showCard"
  >
    <v-row
      no-gutters
      class="fill-height pb-4"
      :style="{
        color: $vuetify.theme.dark ? '#fff !important' : '#505253 !important',
      }"
    >
      <v-col
        cols="12"
        class="text-center text-body-1 font-weight-bold pb-2"
        v-if="$props.showDay"
      >
        <span>{{ $props.eventDate }}</span>
      </v-col>

      <v-col cols="4" class="d-flex align-center justify-center">
        <span class="pa-0 d-flex justify-center text-body-1 font-weight-bold">
          {{ $props.dateText }}
        </span>
      </v-col>
      <v-col cols="8" class="d-flex">
        <div
          class="timeline-card-border use-border rounded-lg"
          :style="{ 'background-color': getColor + ' !important' }"
        />

        <div>
          <v-row
            no-gutters
            v-for="(events, index) in eventsComputed"
            :key="index"
            class="pl-3"
          >
            <v-col
              cols="12"
              class="py-1 d-flex align-center"
              v-for="(e, ind) in events"
              :key="ind"
              v-show="e.text"
            >
              <span
                style="font-size: 14px"
                :style="{
                  color: e.color
                    ? e.color.includes('n2p')
                      ? `var(--v-${e.color}-base) !important`
                      : e.color
                    : null,
                }"
              >
                {{ $i18n.te(e.text) ? $i18n.t(e.text) : e.text }}
              </span>

              <div class="number-information ml-3">
                <span>{{ e.amount }}</span>
              </div>

              <!-- <v-badge
                color="primary"
                :content="e.amount"
                class="pl-3"
                v-if="e.amount > 1"
              /> -->
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { channels } from "@/utils";

export default {
  props: {
    showDay: { type: Boolean, default: false },
    dateText: {
      type: String,
      default: "",
    },
    events: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
    },
    eventDate: {
      type: String,
    },
    filters: {
      type: Object,
    },
  },
  data() {
    return {
      showCard: true,
    };
  },
  computed: {
    eventsComputed() {
      let aux = [];
      this.$props.events.forEach(event => {
        let list = event.filter(e => {
          if (
            (this.$props.filters.login && e.period === "LOGIN") ||
            (this.$props.filters.interaction &&
              (e.period === "WRAPUP" ||
                channels.some(c => e.period === c.value) ||
                e.period.toLowerCase().includes("call"))) ||
            (this.$props.filters.status &&
              e.period.toLowerCase().includes("break"))
          ) {
            return true;
          }

          return false;
        });

        if (!list.length) {
          this.showCard = false;
        } else {
          this.showCard = true;
        }

        aux.push(list);
      });

      return aux;
    },
    getColor() {
      return this.$props.color && this.$props.color.includes("n2p-")
        ? "var(--v-" + this.$props.color + "-base)"
        : this.$props.color;
    },
  },
};
</script>

<style lang="scss">
.number-information {
  width: auto;
  height: 14px;
  background-color: var(--v-primary-base);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: white;
  overflow: hidden;
  word-wrap: break-word;
  padding: 0 4px;
}
</style>
