var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FormBasicLayout',{attrs:{"title":"Keyboard shortcuts","size":"md","showActions":false},on:{"back":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('v-data-table',{staticClass:"text--secondary",attrs:{"headers":[
        { text: 'Action', value: 'action', sortable: false },
        {
          text: 'Shortcut',
          value: 'shortcut',
          align: 'right',
          sortable: false,
        },
      ],"items":_vm.shortcuts,"items-per-page":-1,"hide-default-footer":"","fixed-header":""},scopedSlots:_vm._u([{key:`header.action`,fn:function({ header }){return [_vm._v(" "+_vm._s(_vm.$i18n.t(header.text))+" ")]}},{key:`header.shortcut`,fn:function({ header }){return [_vm._v(" "+_vm._s(_vm.$i18n.t(header.text))+" ")]}},{key:`item.action`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-1",attrs:{"size":"18px"}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(_vm.$i18n.t(item.action))+" ")]}},{key:`item.shortcut`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.key + item.command)+" ")]}}],null,true)})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }