<template>
  <div
    class="d-flex justify-space-between flex-nowrap overflow-x-auto overflow-y-hidden"
  >
    <div class="d-flex align-center">
      <ToolbarMenuItem
        v-for="(item, index) in items.left.filter(item => item.show())"
        :key="index"
        v-bind="item"
      />
      <slot name="left"></slot>
    </div>
    <div class="d-flex align-center" style="gap: 8px">
      <ToolbarMenuItem
        v-for="(item, index) in items.right.filter(item => item.show())"
        :key="index"
        v-bind="item"
      />
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
import ToolbarMenuItem from "@/components/base/VueEditor/ToolbarMenuItem";

export default {
  name: "EditorToolbar",
  components: {
    ToolbarMenuItem,
  },
  props: {
    editor: {
      type: Object,
      required: true,
    },
    addAtachments: { type: Boolean },
    alterView: { type: Boolean, default: true },
    actualColor: { type: String },
  },
  data() {
    return {
      contador: 0,
      items: {
        left: [
          {
            icon: "format-text",
            title: "Paragraph",
            action: () => null,
            submenu: [
              {
                icon: "format-bold",
                title: "Bold",
                action: () => this.editor.chain().focus().toggleBold().run(),
                isActive: () => this.editor.isActive("bold"),
                show: () => true,
              },
              {
                icon: "format-italic",
                title: "Italic",
                action: () => this.editor.chain().focus().toggleItalic().run(),
                isActive: () => this.editor.isActive("italic"),
                show: () => true,
              },
              {
                icon: "format-underline",
                title: "Underline",
                action: () =>
                  this.editor.chain().focus().toggleUnderline().run(),
                isActive: () => this.editor.isActive("underline"),
                show: () => true,
              },
              {
                icon: "format-strikethrough",
                title: "Strike",
                action: () => this.editor.chain().focus().toggleStrike().run(),
                isActive: () => this.editor.isActive("strike"),
                show: () => true,
              },
              {
                icon: "format-color-highlight",
                title: "Highlight",
                action: () =>
                  this.editor.chain().focus().toggleHighlight().run(),
                isActive: () => this.editor.isActive("highlight"),
                show: () => true,
              },
              {
                icon: "format-list-bulleted",
                title: "Bullet List",
                action: () =>
                  this.editor.chain().focus().toggleBulletList().run(),
                isActive: () => this.editor.isActive("bulletList"),
                show: () => true,
              },
              {
                icon: "format-list-numbered",
                title: "Ordered List",
                action: () =>
                  this.editor.chain().focus().toggleOrderedList().run(),
                isActive: () => this.editor.isActive("orderedList"),
                show: () => true,
              },

              // {
              //   icon: "format-paragraph",
              //   title: "Paragraph",
              //   action: () => this.editor.chain().focus().setParagraph().run(),
              //   isActive: () => this.editor.isActive("paragraph"),
              //   show: () => true,
              // },
            ],
            show: () => true,
          },
          {
            icon: "format-size",
            title: "Heading",
            action: () => null,
            submenu: [
              {
                icon: "format-header-1",
                title: "Heading 1",
                action: () =>
                  this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
                isActive: () => this.editor.isActive("heading", { level: 1 }),
                show: () => true,
              },
              {
                icon: "format-header-2",
                title: "Heading 2",
                action: () =>
                  this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
                isActive: () => this.editor.isActive("heading", { level: 2 }),
                show: () => true,
              },
              {
                icon: "format-header-3",
                title: "Heading 3",
                action: () =>
                  this.editor.chain().focus().toggleHeading({ level: 3 }).run(),
                isActive: () => this.editor.isActive("heading", { level: 3 }),
                show: () => true,
              },
              {
                icon: "format-header-4",
                title: "Heading 4",
                action: () =>
                  this.editor.chain().focus().toggleHeading({ level: 4 }).run(),
                isActive: () => this.editor.isActive("heading", { level: 4 }),
                show: () => true,
              },
              {
                icon: "format-header-5",
                title: "Heading 5",
                action: () =>
                  this.editor.chain().focus().toggleHeading({ level: 5 }).run(),
                isActive: () => this.editor.isActive("heading", { level: 5 }),
                show: () => true,
              },
              {
                icon: "format-header-6",
                title: "Heading 6",
                action: () =>
                  this.editor.chain().focus().toggleHeading({ level: 6 }).run(),
                isActive: () => this.editor.isActive("heading", { level: 6 }),
                show: () => true,
              },
            ],
            show: () => true,

            // {
            //     if (this.editor.isActive("heading", { level: 1 })) {
            //       this.editor
            //         .chain()
            //         .focus()
            //         .toggleHeading({ level: 2 })
            //         .run();
            //     } else if (this.editor.isActive("heading", { level: 2 })) {
            //       this.editor
            //         .chain()
            //         .focus()
            //         .toggleHeading({ level: 3 })
            //         .run();
            //     } else if (this.editor.isActive("heading", { level: 3 })) {
            //       this.editor
            //         .chain()
            //         .focus()
            //         .toggleHeading({ level: 4 })
            //         .run();
            //     } else if (this.editor.isActive("heading", { level: 4 })) {
            //       this.editor
            //         .chain()
            //         .focus()
            //         .toggleHeading({ level: 5 })
            //         .run();
            //     } else if (this.editor.isActive("heading", { level: 5 })) {
            //       this.editor
            //         .chain()
            //         .focus()
            //         .toggleHeading({ level: 6 })
            //         .run();
            //     } else if (this.editor.isActive("heading", { level: 6 })) {
            //       this.editor
            //         .chain()
            //         .focus()
            //         .toggleHeading({ level: 6 })
            //         .run();
            //     } else {
            //       this.editor
            //         .chain()
            //         .focus()
            //         .toggleHeading({ level: 1 })
            //         .run();
            //     }
            //   },
          },
          {
            icon: "format-paint",
            title: "Color",
            type: "automaticField",
            action: () => null,
            submenu: [],
            field: {
              tag: "colorPicker",
              text: "Color",
              value: this.actualColor,
              rules: () => [],
              action: e => {
                this.editor.chain().focus().setColor(e).run();
              },
            },
            show: () => true,
          },
          {
            icon: "format-paragraph",
            title: "Format Paragraph",
            action: () => null,
            submenu: [
              {
                icon: "format-align-left",
                title: "Align Left",
                action: () =>
                  this.editor.chain().focus().setTextAlign("left").run(),
                isActive: () => this.editor.isActive("alignLeft"),
                show: () => true,
              },
              {
                icon: "format-align-center",
                title: "Align Center",
                action: () =>
                  this.editor.chain().focus().setTextAlign("center").run(),
                isActive: () => this.editor.isActive("alignCenter"),
                show: () => true,
              },
              {
                icon: "format-align-right",
                title: "Align Right",
                action: () =>
                  this.editor.chain().focus().setTextAlign("right").run(),
                isActive: () => this.editor.isActive("alignRight"),
                show: () => true,
              },
              {
                icon: "format-align-justify",
                title: "Justify",
                action: () =>
                  this.editor.chain().focus().setTextAlign("justify").run(),
                isActive: () => this.editor.isActive("alignJustify"),
                show: () => true,
              },

              {
                icon: "format-quote-open",
                title: "Blockquote",
                action: () =>
                  this.editor.chain().focus().toggleBlockquote().run(),
                isActive: () => this.editor.isActive("blockquote"),
                submenu: [],
                show: () => true,
              },

              // {
              //   icon: "format-clear",
              //   title: "Clear Format",
              //   action: () =>
              //     this.editor
              //       .chain()
              //       .focus()
              //       .clearNodes()
              //       .unsetAllMarks()
              //       .run(),
              //   isActive: () => null,
              //   submenu: [],
              //   show: () => true,
              // },
            ],
            show: () => true,
          },
          {
            icon: "minus",
            title: "Horizontal Rule",
            action: () => this.editor.chain().focus().setHorizontalRule().run(),
            isActive: () => null,
            submenu: [],
            show: () => true,
          },
          {
            icon: "wrap",
            title: "Hard Break",
            action: () => this.editor.chain().focus().setHardBreak().run(),
            isActive: () => null,
            submenu: [],
            show: () => true,
          },
          {
            icon: "table-cog",
            title: "Table config",
            action: () => null,
            submenu: [
              {
                icon: "table-plus",
                title: "Add Table",
                isActive: () => null,
                action: () =>
                  this.editor
                    .chain()
                    .focus()
                    .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                    .run(),
                show: () => true,
              },
              {
                icon: "table-column-plus-after",
                title: "Add column",
                action: () =>
                  this.editor.chain().focus().addColumnAfter().run(),
                isActive: () => null,
                show: () => true,
              },
              {
                icon: "table-column-remove",
                title: "Remove column",
                action: () => this.editor.chain().focus().deleteColumn().run(),
                isActive: () => null,

                show: () => true,
              },
              {
                icon: "table-merge-cells",
                title: "Merge cells",
                action: () => this.editor.chain().focus().mergeCells().run(),
                isActive: () => null,

                show: () => true,
              },
              {
                icon: "table-row-plus-after",
                title: "Add row",
                action: () => this.editor.chain().focus().addRowAfter().run(),
                isActive: () => null,

                show: () => true,
              },
              {
                icon: "table-row-remove",
                title: "Remove row",
                action: () => this.editor.chain().focus().deleteRow().run(),
                isActive: () => null,

                show: () => true,
              },
              {
                icon: "format-color-fill",
                title: "Toggle header row",
                action: () =>
                  this.editor.chain().focus().toggleHeaderRow().run(),
                isActive: () => null,

                show: () => true,
              },
            ],
            show: () => true,
          },
          {
            icon: "link",
            title: "Link",
            action: () =>
              this.editor.isActive("link")
                ? this.editor.chain().focus().unsetLink().run()
                : this.$emit("open-link-dialog"),
            isActive: () => this.editor.isActive("link"),
            submenu: [],
            show: () => true,
          },
          {
            icon: "arrow-u-left-top",
            title: "Undo",
            action: () => this.editor.chain().focus().undo().run(),
            isActive: () => null,
            submenu: [],
            show: () => true,
          },
          {
            icon: "arrow-u-right-top",
            title: "Redo",
            action: () => this.editor.chain().focus().redo().run(),
            isActive: () => null,
            submenu: [],
            show: () => true,
          },

          // {
          //   icon: "format-list-checkbox",
          //   title: "Task List",
          //   action: () => this.editor.chain().focus().toggleTaskList().run(),
          //   isActive: () => this.editor.isActive("taskList"),
          //   show: () => true,
          // },

          {
            icon: "paperclip",
            title: "Uploads",
            action: () => this.$emit("upload-image"),
            submenu: [
              {
                icon: "image-outline",
                title: "Upload image to body",
                action: () => this.$emit("upload-image"),
                isActive: () => null,

                show: () => true,
              },
              {
                icon: "file-upload",
                title: "Upload file",
                action: () => this.$emit("upload-file"),
                submenu: [],
                isActive: () => null,

                show: () => this.addAtachments,
              },
            ],
            show: () => true,
          },
        ],
        right: [
          {
            icon: "monitor-edit",
            title: "View code",
            action: () => this.$emit("alter-view"),
            submenu: [],
            isActive: () => null,

            show: () => this.alterView,
          },
          {
            icon: "monitor-eye",
            title: "Preview",
            action: () => this.$emit("alter-view"),
            submenu: [],
            isActive: () => null,

            show: () => !this.alterView,
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss">
.divider {
  width: 1px;
  border-radius: 2px;
  height: 18px;
  background-color: #e0e0e0;
}
</style>
