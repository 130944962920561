<template>
  <FormBasicLayout
    title="editor.link"
    :showActions="true"
    size="sm"
    @back="$emit('back')"
    @continue="$emit('continue', url)"
  >
    <template #form>
      <v-form ref="form">
        <v-row no-gutters>
          <v-col class="pa-3">
            <AutomaticField :field="field" v-model="url" />
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormBasicLayout>
</template>

<script>
import { required, urlValidator } from "@/utils/validation";

export default {
  name: "LinkDialog",

  props: {
    oldUrl: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      field: {
        tag: "textfield",
        text: "URL",
        value: "url",
        type: () => "text",

        info: "editor.linkInfo",
        rules: () => [required, urlValidator],
      },
      url: "",
    };
  },

  mounted() {
    if (this.oldUrl) this.url = this.oldUrl;
  },
};
</script>

<style scoped></style>
