<template>
  <div
    class="chart-container"
    :style="styles"
    style="width: 100%; height: 100%; overflow: auto"
  >
    <div
      :style="{ height: height, width: width }"
      style="width: 100%; min-height: 100%"
    >
      <canvas
        id="SankeyChart"
        :class="cssClasses"
        style="width: 100%; height: 100%"
      ></canvas>
    </div>
  </div>
</template>

<script>
import { SankeyController, Flow } from "chartjs-chart-sankey";
import { Chart as ChartJS } from "chart.js";
import { colors } from "@/utils";

ChartJS.register(SankeyController, Flow);

export default {
  name: "SankeyChart",

  props: {
    data: {
      type: Object,
    },
    options: {
      type: Object,
    },
    chartId: {
      type: String,
      default: "sankey-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },

    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loaded: false,
      chart: null,
      height: null,
      width: null,
    };
  },

  async mounted() {
    await this.calcSize();
    this.renderChart();
  },

  methods: {
    calcSize() {
      let levels = Object.values(this.data.columns);
      let items = {};

      for (let i of levels) {
        items[i] = items[i] === undefined ? 1 : items[i] + 1;
      }

      let maxH = Math.max(...Object.values(items));
      let maxW = levels.length;

      if (maxH <= 5) maxH = 0;
      maxW <= 5 ? (maxW = 0) : (maxW -= 5);

      let height = maxH * 50;
      let width = maxW * 20;

      this.height = "calc(100% + " + height + "px)";
      this.width = "calc(100% + " + width + "px)";
    },
    updateChart() {
      this.chart.update();
    },

    renderChart() {
      this.chart = new ChartJS("SankeyChart", {
        type: "sankey",

        data: {
          datasets: [
            {
              label: "Sankey",
              labels: this.data.labels,
              data: this.data.data,
              column: this.data.columns,
              size: "max",

              colorFrom: label =>
                this.data.colors[label.raw.parentId] ||
                colors[label.datasetIndex] ||
                "#ff0000",
              colorTo: label =>
                this.data.colors[label.raw.id] ||
                colors[label.datasetIndex] ||
                "#00ff00",
              borderWidth: 0,
            },
          ],
        },
        options: {
          parsing: {
            from: "parentId",
            to: "id",
            flow: "flow",
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: context => {
                  var label =
                    this.data.fullLabels[context.raw.parentId] ||
                    context.raw.parentId;

                  label +=
                    "➡️" + this.data.fullLabels[context.raw.id] ||
                    context.raw.id;

                  label += ": " + context.raw.flow;

                  return label;
                },
                labelColor: context => {
                  console.log();

                  return {
                    borderColor: "",
                    backgroundColor:
                      this.data.colors[context.raw.id] ||
                      colors[context.datasetIndex] ||
                      "red",
                    borderWidth: 2,
                    borderDash: [0, 0],
                    borderRadius: 0,
                  };
                },
              },
            },
            scales: {
              x: {
                title: {
                  display: false,
                },
              },
              y: {
                title: {
                  display: false,
                },
              },
            },
            datalabels: {
              display: false,
            },
            legend: {
              display: false,
            },
          },
        },
      });
    },
  },
  watch: {
    "$props.data": {
      handler: function (val) {
        if (val) {
          this.renderChart();
        }
      },
      deep: true,
    },
  },
};
</script>

<style></style>
