import { defineStore } from "pinia";
import Phone from "@/controllers/phone";
import { luxon } from "@/utils";

const phone = new Phone();
const testPhone = new Phone();

export const usePhoneStore = defineStore("phone", {
  state: () => {
    return {
      // phone: {},
      phoneState: "",
      callState: "available",
      testState: "",
      testCallState: "available",
      client: {},
      dates: { startRinging: "", startCall: "", startCalling: "" },
    };
  },
  actions: {
    getPhone() {
      return phone;
    },
    getTestPhone() {
      return testPhone;
    },
    setPhoneState(state, isTest) {
      if (isTest) {
        this.testState = state;

        return;
      }
      this.phoneState = state;
    },
    setCallState(state, isTest) {
      if (isTest) {
        this.testCallState = state;

        return;
      }
      this.callState = state;
      if (state == "ringing") {
        this.dates.startRinging = luxon().now();
      } else if (state == "onCall") {
        this.dates.startCall = luxon().now();
      } else if (state == "calling") {
        this.dates.startCalling = luxon().now();
      }
    },
    setClient(client, isTest) {
      if (isTest) return;
      this.client = client;
    },
  },
  undo: {
    disable: true,
  },
});
