import { useAxios, validateSecurity } from "@/utils";

export default class Profile {
  constructor(data = {}) {
    this.name = data.name || null;
    this.icon = data.icon || "mdi-pencil-outline";
    this.controllers = data.controllers || [];
  }

  static async get() {
    if (validateSecurity("profiles")) {
      let res = await useAxios({
        method: "GET",
        url: `/api/profiles`,
        headers: {},
      });
      if (res.success) {
        res = res.res;
        let profiles = JSON.parse(res.request.response).result;
        if (profiles) {
          profiles = profiles.map(e => new Profile(e));

          profiles.sort((a, b) => {
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }

            return 0;
          });
        } else {
          profiles = [];
        }

        return profiles;
      } else {
        console.log(res.res);
      }
    }

    return [];
  }

  static async create(profile) {
    if (validateSecurity("profiles", "create")) {
      const res = await useAxios({
        method: "POST",
        url: `/api/profiles/id/${profile.name}`,
        data: {
          profile: profile.name,
          security: profile,
        },
        headers: {},
      });

      if (!res.success) {
        console.log(res.res);
      }

      return res;
    }
  }

  static async update(profile) {
    if (validateSecurity("profiles", "update")) {
      let res = await useAxios({
        method: "PUT",
        url: `/api/profiles/id/${profile.name}`,
        data: {
          profile: profile.name,
          security: profile,
        },
        headers: {},
      });
      if (!res.success) {
        console.log(res.res);
      }

      return res;
    }
  }

  static async delete(profile) {
    if (validateSecurity("profiles", "delete")) {
      const res = await useAxios({
        method: "DELETE",
        url: `/api/profiles/id/${profile.name}`,
        headers: {},
      });

      if (!res.success) {
        console.log(res.res);
      }

      return res;
    }
  }
}
