<template>
  <div style="width: 100%; height: 100%">
    <div class="fill-height d-flex flex-column">
      <v-tooltip
        bottom
        v-for="button in buttons.filter(e => e.show())"
        :key="button.text"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="float-btn elevation-0"
            @click="button.action()"
            :class="button.class"
            v-bind="attrs"
            v-on="on"
            height="36"
            width="36"
            min-width="36"
          >
            <v-icon>
              {{ button.icon() }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.t(button.text) }}</span>
      </v-tooltip>

      <div class="pt-0" style="flex: 1">
        <iframe
          :src="form"
          :title="form"
          width="100%"
          style="height: 100%; border: none"
          :id="'form-iframe-' + $props.data.guid"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from "@/utils";
import WindowProxy from "@/controllers/windowProxy";

export default {
  name: "FormContainer",
  props: {
    data: {
      type: Object,
    },
  },

  data() {
    return {
      fullscreen: false,
      form: null,
      buttons: [
        {
          text: "Fullscreen",
          icon: () =>
            this.fullscreen ? "mdi-fullscreen-exit" : "mdi-fullscreen",
          action: () => this.handleFullScreen(),
          show: () => !this.$vuetify.breakpoint.smAndDown,
          class: "fullscreen-btn",
        },
        {
          text: "Collapse",
          icon: () => "mdi-chevron-right",
          action: () => this.$emit("close"),
          show: () => true,
        },
      ],
    };
  },

  mounted() {
    this.init();
    window.addEventListener("toggleFullscreen", e => {
      if (e?.detail?.origin == "ucx") return;
      this.handleFullScreen();
    });
    this.init();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    init() {
      this.$emit("fullscreen", this.fullscreen);
      if (this.$props.data && this.$props.data.form) {
        if (this.$props.data.form == "CRM form")
          this.form = useAppStore().user.crmDomain + "/interaction";
        else this.form = this.$props.data.form;

        //Add query params to this.form data.guid, data.clientId, data.clientName, data.campaign, data.channel, data.contactId
        if (this.$props.data) {
          const {
            guid,
            clientId,
            clientName,
            campaign,
            channel,
            contactId,
            data,
          } = this.$props.data;
          this.form += `?guid=${guid}&clientId=${clientId}&clientName=${clientName}&campaign=${campaign}&channel=${channel}`;
          if (contactId) this.form += `&contactId=${contactId}`;

          if (data && Object.keys(data).length > 0)
            this.form += `&data=${encodeURIComponent(JSON.stringify(data))}`;
        }
      }
    },
    handleFullScreen() {
      let { form } = this.$props.data;

      if (form && this.fullscreen) {
        useAppStore().removeFullscreenForm(form);
        this.fullscreen = false;
      } else if (form) {
        useAppStore().addFullscreenForm(form);
        this.fullscreen = true;
      }

      WindowProxy.toggleFullscreen(this.fullscreen);
    },
  },
};
</script>

<style lang="scss" scoped>
.float-btn {
  position: absolute;
  z-index: 100;
  top: 12px;
  border-radius: 20px;

  &.fullscreen-btn {
    right: 20px;
  }
}
</style>
