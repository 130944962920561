import { useAxios, validateSecurity, dateToLocalTime } from "@/utils";
import Flow, { FlowHistoric } from "@/controllers/flow";

export default class Webhook {
  static async get() {
    if (validateSecurity("webhooks")) {
      let res = await useAxios({
        method: "GET",
        url: `/api/webhooks`,
        headers: {},
      });
      if (res.success) {
        let flows = JSON.parse(res.res.request.response).result
          ? JSON.parse(res.res.request.response).result
          : [];
        flows = flows.map(c => {
          c.deployDate = c.deployDate
            ? dateToLocalTime(c.deployDate).toFormat("yyyy-MM-dd TT")
            : null;

          return new Flow(c);
        });

        flows.sort((a, b) => {
          let x = a.name.toLowerCase();
          let y = b.name.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }

          return 0;
        });

        return flows;
      }
    }

    return [];
  }
  static async getHistoricPage(flow, index, rows) {
    if (validateSecurity("webhooks")) {
      let res = await useAxios({
        method: "GET",
        url: `/api/webhooks/id/${flow}/historic?index=${index}&rows=${rows}`,
        headers: {},
      });
      if (res.success) {
        let flows = JSON.parse(res.res.request.response).result
          ? JSON.parse(res.res.request.response).result
          : [];
        flows = flows.map(c => {
          c.date = dateToLocalTime(c.date).toFormat("yyyy-MM-dd TT");

          return new FlowHistoric(c);
        });

        flows.sort((a, b) => {
          let x = a.name.toLowerCase();
          let y = b.name.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }

          return 0;
        });

        return flows;
      }
    }

    return [];
  }
  static async create(flow) {
    if (validateSecurity("webhooks", "create")) {
      const res = await useAxios({
        method: "POST",
        url: `/api/webhooks/id/${flow.name}`,
        data: flow,
        headers: {},
      });

      if (!res.success) {
        console.log(res.res);
      }

      return res;
    }
  }
  static async update(flow) {
    if (validateSecurity("webhooks", "update")) {
      const res = await useAxios({
        method: "PUT",
        url: `/api/webhooks/id/${flow.name}`,
        data: flow,
        headers: {},
      });

      if (!res.success) {
        console.log(res.res);
      }

      return res;
    }
  }
  static async delete(flow) {
    if (validateSecurity("webhooks", "delete")) {
      const res = await useAxios({
        method: "DELETE",
        url: `/api/webhooks/id/${flow.name}`,
        data: flow,
        headers: {},
      });

      if (!res.success) {
        console.log(res.res);
      }

      return res;
    }
  }
}
