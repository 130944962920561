import Profile from "@/controllers/profile";
import { useAppStore, useAxios } from "@/utils";

export default class Auth {
  constructor(data = {}) {
    this.username = data.username || null;
    this.phone = data.phone || null;
    this.md5Secret = data.md5Secret || null;
    this.host = data.host || null;
    this.token = data.token || null;
    this.security = data.security || new Profile();
  }

  static async login(username, password) {
    const res = await useAxios({
      method: "POST",
      url: `/api/auth/login/`,
      data: {
        username: username,
        password: password,
        language: "es",
      },
    });

    if (!res.success) {
      console.log(res.res);
    }

    return res;
  }

  static async resetPasswordLogin(id, password) {
    const res = await useAxios({
      method: "POST",
      url: `/api/auth/changePasswordLogin`,
      data: {
        id: id,
        password: password,
      },
    });
    if (!res.success) {
      console.log(res.res);
    }

    return res;
  }
  static async resetPassword(data) {
    const res = await useAxios({
      method: "POST",
      url: `/api/auth/id/${useAppStore().user.username}/changePassword`,
      data: data,
      headers: {},
    });
    if (!res.success) {
      console.log(res.res);
    }

    return res;
  }

  static async validateResetPasswordToken(token) {
    const res = await useAxios({
      method: "POST",
      url: `/api/auth/validateResetPasswordToken`,
      data: { token: token },
      headers: {},
    });
    if (!res.success) {
      console.log(res.res);
    }

    return res.success;
  }
  static async sendForgottenPasswordEmail(agent) {
    const res = await useAxios({
      method: "POST",
      url: `/api/auth/sendForgottenPasswordEmail`,
      data: {
        agent: agent,
        language: useAppStore().preferences[agent]?.language || "en",
      },
    });
    if (!res.success) {
      console.log(res.res);
    }

    return res;
  }
}
