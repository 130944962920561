<template>
  <div
    class="d-flex align-center fill-height"
    :class="$props.vertical ? 'flex-column ' : ''"
  >
    <div
      style="width: 32px; cursor: pointer"
      @click="scrollLeft()"
      :disabled="!$props.showArrowLeft"
      class="d-flex cursor-pointer"
    >
      <v-icon style="color: inherit" size="32px"
        >mdi-chevron-{{ $props.vertical ? "up" : "left" }}</v-icon
      >
    </div>
    <div
      :style="{ [$props.vertical ? 'height' : 'width']: 'calc(100% - 70px)' }"
      class="d-flex justify-center fill-height align-center"
      :class="
        $props.vertical ? 'flex-column overflow-y-auto' : 'overflow-x-hidden'
      "
      style="min-width: 48px"
    >
      <slot name="content"> </slot>
    </div>
    <div
      style="width: 32px; cursor: pointer"
      @click="scrollRight()"
      :disabled="!$props.showArrowRight"
      class="d-flex"
    >
      <v-icon style="color: inherit" size="32px"
        >mdi-chevron-{{ $props.vertical ? "down" : "right" }}</v-icon
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "HorizontalScrollArrows",

  props: {
    scroll: { type: Number },
    vertical: { type: Boolean, default: false },
    showArrowLeft: { type: Boolean, default: true },
    showArrowRight: { type: Boolean, default: true },
  },

  methods: {
    scrollLeft() {
      if (this.$props.showArrowLeft) {
        this.$emit("left");
      }
    },

    scrollRight() {
      if (this.$props.showArrowRight) {
        this.$emit("right");
      }
    },
  },
};
</script>
