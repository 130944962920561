var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('AppMenuBar',{attrs:{"disableMenu":!_vm.filteredMenu[_vm.selectedMenu]?.actionSubmenu},on:{"connectInbox":function($event){return _vm.$emit('connectInbox')},"openMenu":function($event){_vm.drawer = !_vm.drawer},"home":function($event){return _vm.goToRoute('/')},"logout":function($event){return _vm.$emit('logout')}}}),_c('v-navigation-drawer',{directives:[{name:"hotkey",rawName:"v-hotkey",value:({
      'alt+m': () => {
        _vm.openSubmenu();
      },
      'alt+p': () => {
        _vm.goToPortal();
      },
      'alt+i': () => {
        _vm.goToInbox();
      },

      //'alt+c': () => {
      //  goToCalendar();
      //},
    }),expression:"{\n      'alt+m': () => {\n        openSubmenu();\n      },\n      'alt+p': () => {\n        goToPortal();\n      },\n      'alt+i': () => {\n        goToInbox();\n      },\n\n      //'alt+c': () => {\n      //  goToCalendar();\n      //},\n    }"}],ref:"menu",staticStyle:{"z-index":"7","transition":"all 500ms ease-in"},style:({
      'background-color':
        _vm.useInboxStore().pause.status && _vm.useInboxStore().pause.status.name
          ? '#9B9D9E !important'
          : 'var(--v-primary-base) !important',
    }),attrs:{"app":"","touchless":"","mini-variant":"","floating":"","mini-variant-width":"50"}},[_c('div',{staticClass:"fill-height d-flex flex-column justify-space-between"},[_c('div',[_c('div',{staticClass:"d-flex justify-center align-center pt-2 py-3",staticStyle:{"width":"100%","aspect-ratio":"1"}},[(_vm.filteredMenu[_vm.selectedMenu]?.actionSubmenu)?_c('v-btn',{attrs:{"icon":"","dark":"","disabled":!_vm.filteredMenu[_vm.selectedMenu].actionSubmenu},on:{"click":function($event){_vm.filteredMenu[_vm.selectedMenu]?.actionSubmenu?.()}}},[_c('v-icon',[_vm._v("mdi-menu")])],1):_vm._e()],1),_c('v-list',{staticClass:"py-0 text-center transparent"},_vm._l((_vm.filteredMenu),function(item,index){return _c('v-tooltip',{key:index,attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-list-item',_vm._g({class:item.class,staticStyle:{"transition":"all 0.2s ease","max-height":"46px","min-height":"46px !important","margin-bottom":"2px"},style:([
                  {
                    'background-color':
                      _vm.selectedMenu === index || _vm.menuHovered === index
                        ? 'var(--v-background-base)'
                        : null,
                  },
                ]),on:{"mouseover":function($event){(_vm.menuHovered = index), item.onHover && item.onHover()},"mouseout":function($event){(_vm.menuHovered = null), item.onLeave && item.onLeave()},"click":function($event){$event.preventDefault();return _vm.clickMenu(index)}}},on),[(
                    item.text == 'Inbox' &&
                    !_vm.useInboxStore().spying &&
                    (_vm.interactionsInProgress.filter(
                      interaction =>
                        interaction.unread &&
                        interaction.guid != _vm.selectedInteraction
                    ).length ||
                      _vm.ringingInteractions.length) &&
                    _vm.selectedMenu != 1
                  )?_c('v-badge',{attrs:{"top":"","color":_vm.ringingInteractions.length ? 'warning' : 'error',"offset-y":"10","offset-x":"10","small":"","icon":_vm.ringingInteractions.length ? 'mdi-bell-outline' : null,"content":_vm.ringingInteractions.length
                      ? null
                      : _vm.interactionsInProgress.filter(
                          interaction =>
                            interaction.unread &&
                            interaction.guid != _vm.selectedInteraction
                        ).length}},[_c('v-icon',{attrs:{"color":_vm.selectedMenu === index
                        ? 'primary'
                        : _vm.$vuetify.theme.dark
                        ? null
                        : 'white'}},[_vm._v(_vm._s(item.icon))])],1):_c('v-icon',{attrs:{"color":_vm.selectedMenu === index
                      ? 'primary'
                      : _vm.$vuetify.theme.dark
                      ? null
                      : 'white'}},[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$i18n.t(item.text))+" ")])])}),1)],1),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticStyle:{"aspect-ratio":"1"},attrs:{"icon":"","dark":"","width":"100%","height":"auto"},on:{"click":function($event){_vm.dialogs.about = true}}},on),[_c('v-icon',[_vm._v("mdi-information-variant")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("About uContact")))])])],1)]),(_vm.$vuetify.breakpoint.mdAndDown && !_vm.useInboxStore().hideMenu)?_c('v-app-bar',{staticClass:"customAppBar",attrs:{"app":"","flat":"","bottom":"","absolute":"","height":"50px","color":"primary","content-class":"py-0"}},[_c('v-row',{staticClass:"align-center fill-height",attrs:{"no-gutters":""}},[_c('v-spacer'),_vm._l((_vm.filteredMenu),function(item,index){return _c('v-btn',{key:index,staticClass:"mx-2 elevation-0",class:item.class,staticStyle:{"transition":"all 0.2s ease","height":"100%"},style:([
          {
            'background-color':
              _vm.selectedMenu === index || _vm.menuHovered === index
                ? _vm.colorPerTheme('accent')
                : null,
          },
        ]),attrs:{"text":"","tile":"","color":_vm.selectedMenu === index || _vm.menuHovered === index ? null : 'white'},on:{"mouseover":function($event){(_vm.menuHovered = index), item.onHover && item.onHover()},"mouseout":function($event){(_vm.menuHovered = null), item.onLeave && item.onLeave()},"click":function($event){$event.preventDefault();return _vm.clickMenu(index)}}},[(
            item.text == 'Inbox' &&
            (_vm.interactionsInProgress.filter(
              interaction =>
                interaction.unread && interaction.guid != _vm.selectedInteraction
            ).length ||
              _vm.ringingInteractions.length) &&
            _vm.selectedMenu != 1
          )?_c('v-badge',{attrs:{"top":"","color":_vm.ringingInteractions.length ? 'warning' : 'error',"offset-y":"10","offset-x":_vm.interactionsInProgress.length > 99 ? 20 : 10,"small":"","icon":_vm.ringingInteractions.length ? 'mdi-bell-outline' : null,"content":_vm.ringingInteractions.length
              ? null
              : _vm.interactionsInProgress.filter(
                  interaction =>
                    interaction.unread &&
                    interaction.guid != _vm.selectedInteraction
                ).length}},[_c('v-icon',{attrs:{"color":_vm.selectedMenu === index
                ? 'primary'
                : _vm.$vuetify.theme.dark
                ? null
                : 'white'}},[_vm._v(_vm._s(item.icon))])],1):_c('v-icon',{attrs:{"color":_vm.selectedMenu === index
              ? 'primary'
              : _vm.$vuetify.theme.dark
              ? null
              : 'white'}},[_vm._v(_vm._s(item.icon))])],1)}),_c('v-spacer')],2)],1):_vm._e(),(_vm.navigation && _vm.selectedMenu == 0)?_c('v-navigation-drawer',{staticClass:"appMenuSubmenu",style:([
      { 'margin-left': _vm.$vuetify.breakpoint.mdAndDown ? null : '50px' },
      {
        'border-top': _vm.$vuetify.theme.dark
          ? 'solid thin rgba(255, 255, 255, 0.12)'
          : 'solid thin rgba(0, 0, 0, 0.12)',
      },
    ]),attrs:{"value":_vm.drawer && !!_vm.filteredMenu[_vm.selectedMenu]?.actionSubmenu,"app":"","clipped":"","floating":"","width":_vm.drawer ? (_vm.$vuetify.breakpoint.smAndDown ? '60%' : '210') : '0'},on:{"input":_vm.checkDrawer}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-img',{attrs:{"src":require(`@/assets/logo/uContact_light.svg`),"contain":"","max-height":"56"}}):_vm._e(),_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.drawer),expression:"drawer"}],staticClass:"transparent",attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"value-comparator":() => {},"color":"primary"}},_vm._l((_vm.filteredMenu[_vm.selectedMenu]?.submenu),function(subitem,i){return _c('div',{key:i},[(
              subitem.text && subitem.options.filter(m => m.security()).length
            )?_c('v-subheader',[_c('span',{staticClass:"mr-2",staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.$i18n.t(subitem.text)))])]):_vm._e(),_vm._l((subitem.options.filter(m => m.security())),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.clickSubmenu(item.route)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"size":"20px","color":_vm.$route.path == item.route ? 'var(--v-primary-base)' : ''}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{style:({
                  color:
                    _vm.$route.path == item.route
                      ? 'var(--v-primary-base)'
                      : 'var(--text)',
                  'font-weight': _vm.$route.path == item.route ? ' bold' : '',
                })},[_vm._v(_vm._s(_vm.$i18n.t(item.text)))])],1)],1)})],2)}),0)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"max-width":"400px"},model:{value:(_vm.dialogs.logout),callback:function ($$v) {_vm.$set(_vm.dialogs, "logout", $$v)},expression:"dialogs.logout"}},[_c('ConfirmForm',{attrs:{"title":"Logout","type":"error"},on:{"back":function($event){_vm.dialogs.logout = false},"continue":function($event){_vm.$emit('logout');
        _vm.logout();}}})],1),(_vm.dialogs.about)?_c('AboutDialog',{on:{"close":function($event){_vm.dialogs.about = false}}}):_vm._e(),(_vm.crmIsEnabled)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dialogCRM),expression:"dialogCRM"}],staticClass:"dialog-crm"},[_c('CRM',{attrs:{"crmDomain":_vm.crmDomain}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }