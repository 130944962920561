import { isEmpty, luxon } from "./index";
import { i18n } from "@/plugins/i18n";

export const required = value =>
  value && String(value) && String(value).length
    ? true
    : i18n.t("Required field");

export const requiredSelect = value => !!value || i18n.t("Required field");

//Permite letras, números, guiones

export const validateSpecialCharacters = value => {
  const regex = /^[0-9a-zA-Z\-_.]+$/;
  if (regex.test(value)) {
    return true;
  } else {
    return i18n.t("This field cannot contain special characters or spaces");
  }
};

//Permite letras, números, guiones y espacios

export const validateSpecialCharactersNotSpaceBar = value => {
  const regex = /^[0-9a-zA-Z\-_\s]+$/;
  if (regex.test(value)) {
    return true;
  } else {
    return i18n.t("This field cannot contain special characters");
  }
};

//Permite letras, números, guiones, espacios y acentos

export const validateSpecialCharactersNotSpaceBarAndAccent = value => {
  const regex = /^[0-9a-zA-Z\-_À-ÿ\s]+$/;
  if (regex.test(value)) {
    return true;
  } else {
    return i18n.t("This field cannot contain special characters");
  }
};

export const validateBatchDowloadFormat = value => {
  const regex = /^[a-zA-Z\\-]+$/;

  return (
    regex.test(value) || i18n.t("Invalid format. Use only letters and '-'")
  );
};

export const validateUser = value => {
  let regex = /^[a-zA-ZÀ-ÿ\s'-]*$/;

  return regex.test(value) || i18n.t("Invalid format");
};

export const validateDNSIpAdress = value => {
  let regex =
    /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;

  return regex.test(value) || i18n.t("Enter a valid IP or DNS");
};

export const emailValidator = value => {
  if (isEmpty(value)) {
    return true;
  }

  const re =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (Array.isArray(value)) {
    return value.every(val => re.test(String(val)));
  }

  return re.test(String(value)) || i18n.t("Enter a valid email");
};

export const passwordValidator = value => {
  if (!value) return true;

  const noSpacesAtEnds = value.trim() === value;

  if (!noSpacesAtEnds) {
    return i18n.t("No spaces are allowed at the beginning or end.");
  }

  return true;
};

export const confirmedValidator = (value, target) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  value === target || i18n.t("Passwords don't match");

export const between = (value, min, max) => () => {
  const valueAsNumber = Number(value);

  return (
    (Number(min) <= valueAsNumber && Number(max) >= valueAsNumber) ||
    `Enter number between ${min} and ${max}`
  );
};

export const integerValidator = value => {
  if (isEmpty(value)) {
    return true;
  }

  if (Array.isArray(value)) {
    return value.every(val => /^-?[0-9]+$/.test(String(val)));
  }

  return (
    /^-?[0-9]+$/.test(String(value)) || "Este campo debe ser un número entero"
  );
};

export const percentage = value => {
  if (String(value) && /^[0-9]*$/.test(String(value))) {
    if (value >= 0 && value <= 100) return true;
  }

  return i18n.t("You must enter a number between 0 and 100");
};

export const integerValidatorThanZero = value => {
  if (isEmpty(value)) {
    return true;
  }

  if (Array.isArray(value)) {
    return value.every(val => /^[1-9][0-9]*$/.test(String(val)));
  }

  return (
    /^[1-9][0-9]*$/.test(String(value)) ||
    "Este campo debe ser un número mayor a 0"
  );
};

export const regexValidator = (value, regex) => {
  if (isEmpty(value)) {
    return true;
  }

  let regeX = regex;
  if (typeof regeX === "string") {
    regeX = new RegExp(regeX);
  }

  if (Array.isArray(value)) {
    return value.every(val => regexValidator(val, { regeX }));
  }

  return regeX.test(String(value)) || "The Regex field format is invalid";
};

export const alphaValidator = value => {
  if (isEmpty(value)) {
    return true;
  }

  // const valueAsString = String(value)

  return (
    /^[A-Z]*$/i.test(String(value)) ||
    "The Alpha field may only contain alphabetic characters"
  );
};

export const urlValidator = value => {
  if (value === undefined || value === null || value.length === 0) {
    return true;
  }
  /* eslint-disable no-useless-escape */
  const re =
    /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;

  return re.test(value) || "URL is invalid";
};

export const validateIpAddress = value => {
  let regex =
    /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  return regex.test(value) || i18n.t("IP is invalid");
};

export const lengthValidator = (value, length) => {
  if (isEmpty(value)) {
    return true;
  }

  return (
    value.length === length ||
    `The Min Character field must be at least ${length} characters`
  );
};

export const alphaDashValidator = value => {
  if (isEmpty(value)) {
    return true;
  }

  const valueAsString = String(value);

  return /^[0-9A-Z_-]*$/i.test(valueAsString) || "All Character is not valid";
};

export const filesValidator = (files, type) => {
  let filesTypes = {
    docs: [
      "application/pdf",
      "application/msword",
      "text/csv",
      "application/vnd.ms-powerpoint",
      "application/vnd.ms-excel",
    ],
    imgs: ["image/jpeg", "image/png"],
    compresseds: ["application/x-rar-compressed", "application/zip"],
  };
  let result;

  if (files.length == 0) {
    result = true;
  }

  if (type == "all") {
    result =
      files.every(
        file =>
          (filesTypes.docs.includes(file.type) ||
            filesTypes.imgs.includes(file.type) ||
            filesTypes.compresseds.includes(file.type)) &&
          file.size <= 52428800
      ) ||
      "Los archivo deben ser un documento o imagen y no deben pesar más de 50mb";
  }

  if (type == "image") {
    result =
      files.every(
        file => filesTypes.imgs.includes(file.type) && file.size <= 52428800
      ) || "Los archivos deben ser una imagen y no deben pesar más de 50mb";
  }

  return result;
};

export const getFailedTabs = (fields, values) => {
  let tabs = [];
  fields.forEach(field => {
    if (field.rules) {
      field.rules().forEach(rule => {
        if (rule(values[field.value]) !== true) tabs.push(field.tab);
      });
    }
  });

  return tabs;
};

export const dateIsBefore = (value, dateToCompare = luxon().toSQL()) => {
  let date = luxon().fromSQL(value);
  dateToCompare = luxon().fromSQL(dateToCompare);

  return (
    date < dateToCompare ||
    i18n.t("The date must be before to", {
      date: dateToCompare.toLocaleString(luxon().DATETIME_SHORT_WITH_SECONDS),
    })
  );
};

export const dateIsAfter = (value, dateToCompare = luxon().toSQL()) => {
  let date = luxon().fromSQL(value);
  dateToCompare = luxon().fromSQL(dateToCompare);

  return (
    date > dateToCompare ||
    i18n.t("The date must be after to", {
      date: dateToCompare.toLocaleString(luxon().DATETIME_SHORT_WITH_SECONDS),
    })
  );
};

export const greaterThan0 = value => {
  return value > 0 || i18n.t("The value must be greater than 0");
};

export const notEqualValidator = (value, targets = []) => {
  let val = targets.find(target => target === value);

  return !val || i18n.t("The value must be different to", { value: val });
};

export const validateOnlyNumbers = value => {
  const regex = /^\d*$/;
  if (regex.test(value) || !value) {
    return true;
  } else {
    return i18n.t("This field only accepts numbers");
  }
};
