import { connect } from "http2";
<template>
  <v-list-item link>
    <v-list-item-avatar>
      <UserAvatar
        v-if="$props.data.avatar && $props.chat"
        :user="{
          avatar: $props.data.userAvatar,
          username: $props.data.subtitle,
          name: $props.data.title,
        }"
        :status="
          $props.data.connected
            ? 'Connected'
            : 'Websocket and Phone not connected'
        "
        :size="40"
      />

      <v-avatar
        v-else-if="$props.data.resource == 'phonebook'"
        size="32"
        style="box-shadow: 0 0 0px 3px var(--v-primary-base) !important"
      >
        <span>{{ getInitals($props.data.title) }}</span>
      </v-avatar>

      <v-avatar
        v-else
        :style="[
          {
            'background-color': $props.data.campaign
              ? channels.find(e => e.value === 'campaign').color
              : null,
          },
        ]"
        :key="$props.data.guid"
        size="40"
      >
        <img
          v-if="$props.data.avatar"
          :src="avatarUrl($props.data.subtitle, $props.data.userAvatar)"
        />
        <v-icon :color="$props.data.campaign ? 'white' : null" v-else>
          {{ $props.data.icon }}
        </v-icon>
      </v-avatar>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>
        <v-row no-gutters>
          <v-col cols="9" class="overflow-x-hidden">
            <span class="text-body-2">
              {{ $props.data.title }}
            </span>
          </v-col>
        </v-row>
      </v-list-item-title>
      <v-list-item-subtitle v-if="$props.data.phone">
        <v-row no-gutters>
          <v-col cols="9" class="overflow-x-hidden">
            <span class="text-caption mr-3">
              <v-icon x-small>mdi-account-outline</v-icon>
              {{ $props.data.subtitle }}
            </span>
            <span class="text-caption">
              <v-icon x-small>mdi-phone-outline</v-icon>
              {{ $props.data.phone }}
            </span>
          </v-col>
        </v-row>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn
        icon
        v-if="$props.data.phone"
        @click.stop="$emit('makeCall', $props.data)"
      >
        <v-icon> mdi-phone-outline </v-icon>
      </v-btn>
      <span class="text-right text-caption"> {{ $props.data.data }} </span>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { channels, avatarUrl } from "@/utils";
import UserAvatar from "@/components/users/UserAvatar.vue";

export default {
  name: "SearchItem",
  components: { UserAvatar },
  props: {
    data: {
      type: Object,
    },
    chat: {
      type: Boolean,
    },
  },

  data() {
    return {
      channels: channels,
      avatarUrl,
    };
  },
  methods: {
    getInitals(contact) {
      let res = "";
      if (contact) {
        const names = contact.split(" ");
        if (names.length > 1) {
          res = names[0].charAt(0) + names[1].charAt(0);
        } else {
          res = names[0].charAt(0) + names[0].charAt(1);
        }
      }

      return res.toUpperCase();
    },
  },
};
</script>
