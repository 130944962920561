import Bot from "@/controllers/bot.js";
import Ivr from "@/controllers/ivr.js";
import Webhook from "@/controllers/webhook.js";
import Blacklist from "@/controllers/blacklist.js";
import { useAxios } from "@/utils";

export default class Flow {
  constructor(data = {}) {
    data = data || {};
    this.name = data.name || null;
    this.description = data.description || null;
    if (Object.prototype.toString.call(data.drawFlowObj) == "[object Object]")
      this.drawFlowObj = data.drawFlowObj;
    else if (data.drawFlowObj) this.drawFlowObj = JSON.parse(data.drawFlowObj);
    else this.drawFlowObj = null;

    if (Object.prototype.toString.call(data.flow) == "[object Array]")
      this.flow = data.flow;
    else if (data.flow) this.flow = JSON.parse(data.flow);
    else this.flow = [];

    this.extension = data.extension || null;
    this.context = data.context || null;
    this.type = data.type || null;
    this.deployed = data.deployed || false;
    this.deployDate = data.deployDate || null;
    this.user = data.user || null;
    this.deployer = data.deployer || null;
    this.enabled =
      data.enabled != null ? data.enabled : data.type == "bot" ? false : true;
  }

  static async getHistoricPage(type, flow, index, rows) {
    const controllers = {
      ivr: Ivr,
      ivrFunction: Ivr,
      ivrSimple: Ivr,
      telephony: Ivr,
      bot: Bot,
      botFunction: Bot,
      webhook: Webhook,
      blacklist: Blacklist,
      channelProvider: Bot,
    };

    if (type == "blacklist")
      return await controllers[type].getFlowHistoricPage(flow, index, rows);
    else return await controllers[type].getHistoricPage(flow, index, rows);
  }

  static async getAudios(flowName, type) {
    let res = await useAxios({
      method: "GET",
      url: `/api/${type}/id/${flowName}/audios`,
      headers: {},
    });
    if (res.success) {
      let functions = JSON.parse(res.res.request.response).result
        ? JSON.parse(res.res.request.response).result
        : [];
      functions.forEach(f => {
        f.name = f.name.replace(flowName + "-", "");
      });

      functions.sort((a, b) => {
        let x = a.name.toLowerCase();
        let y = b.name.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }

        return 0;
      });

      return functions;
    }

    return [];
  }
  static async saveAudio(flowName, type, audio) {
    const res = await useAxios({
      method: "POST",
      url: `/api/${type}/id/${flowName}/audios?name=${audio.name}&language=${audio.language}&extension=${audio.extension}`,
      data: audio.audio,
      headers: {
        "Content-Type": audio.audio.type,
      },
    });

    return res;
  }
  static async deleteAudio(flowName, type, audio) {
    const res = await useAxios({
      method: "DELETE",
      url: `/api/${type}/id/${flowName}/audios?name=${audio.name}&language=${audio.language}`,
      headers: {},
    });

    return res;
  }
}
export class FlowHistoric {
  constructor(data = {}) {
    this.name = data.name || null;
    if (Object.prototype.toString.call(data.drawFlowObj) == "[object Object]")
      this.drawFlowObj = data.drawFlowObj;
    else if (data.drawFlowObj) this.drawFlowObj = JSON.parse(data.drawFlowObj);
    else this.drawFlowObj = null;
    this.deployed = data.deployed || false;
    this.user = data.user || null;
    this.date = data.date || null;
  }
}
