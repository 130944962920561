var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticStyle:{"width":"100%","max-width":"100%","box-shadow":"0px 0px 7.95px 0px rgba(0, 0, 0, 0.1) !important","border-radius":"16px"},style:({
    'background-color': _vm.$vuetify.theme.dark
      ? '#242526b3 !important'
      : 'var(--v-background-accent) !important',
  })},[_c('v-row',{staticClass:"justify-center align-center py-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center text-subtitle-2 card-color-label",style:({
        color: _vm.$props.disabled ? _vm.disabledColor : null,
      }),attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$props.time == null ? _vm.$i18n.t(_vm.$props.channel.text) : _vm.convertSecondsToTimeFormat(_vm.$props.time))+" ")]),_c('v-col',{staticClass:"pt-2",attrs:{"cols":"12"}},[_c('v-progress-circular',{ref:"progressCircular",class:!_vm.$props.disabled ? `circular--${_vm.$props.channel.value}` : null,staticStyle:{"width":"100%","height":"100%","padding":"8px !important"},attrs:{"rotate":"135","width":"2","color":_vm.$props.disabled ? _vm.disabledColor : _vm.$props.channel.color,"rounded":"","value":_vm.total != 0 ? (_vm.$props.inbound / _vm.total) * 100 : 50}},[[_c('v-badge',{attrs:{"bottom":"","offset-x":"20","offset-y":"20","color":"transparent"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-icon',{attrs:{"size":"40px"}})]},proxy:true}])},[_c('v-icon',{staticClass:"pa-2",attrs:{"size":32,"color":_vm.$props.disabled ? _vm.disabledColor : _vm.$props.channel.color}},[_vm._v(" "+_vm._s(_vm.$props.channel.icon)+" ")])],1)]],2)],1),_c('v-col',{staticClass:"pt-3 px-1",attrs:{"cols":"12"}},[(_vm.$props.time == null)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":_vm.$props.channel.value === 'webchat' ? '12' : '6'}},[_c('p',{staticClass:"ma-0 text-caption card-color-label",staticStyle:{"font-size":"10px !important"},style:({
              color: _vm.$props.disabled ? _vm.disabledColor + '!important' : null,
            })},[_vm._v(" "+_vm._s(_vm.$i18n.t("Inbound"))+" ")]),_c('p',{staticClass:"inbound-value",staticStyle:{"font-size":"20px !important"},style:({
              color: _vm.$props.disabled
                ? _vm.disabledColor + '!important'
                : _vm.$props.channel.color,
            })},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.$props.inbound))+" ")])]),(_vm.$props.channel.value != 'webchat')?_c('v-col',{staticClass:"text-center",attrs:{"cols":"6"}},[_c('p',{staticClass:"ma-0 text-caption card-color-label",staticStyle:{"font-size":"10px !important"},style:({
              color: _vm.$props.disabled ? _vm.disabledColor + '!important' : null,
            })},[_vm._v(" "+_vm._s(_vm.$i18n.t("Outbound"))+" ")]),_c('p',{staticClass:"outbound-value",staticStyle:{"font-size":"20px !important"},style:({
              color: _vm.$props.disabled
                ? _vm.disabledColor + '!important'
                : _vm.$props.channel.color,
            })},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.$props.outbound))+" ")])]):_vm._e()],1):_c('v-row',{staticClass:"algin-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"ma-0 text-caption text-center card-color-label",staticStyle:{"font-size":"11px !important"},style:({
              color: _vm.$props.disabled ? _vm.disabledColor + '!important' : null,
            })},[_vm._v(" "+_vm._s(_vm.$i18n.t("Total"))+" ")]),_c('p',{staticClass:"inbound-value text-center",staticStyle:{"font-size":"20px !important"},style:({
              color: _vm.$props.disabled
                ? _vm.disabledColor + '!important'
                : _vm.$props.channel.color,
            })},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.$props.inbound + _vm.$props.outbound))+" ")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }