<template>
  <div
    class="wrapper-about d-flex flex-column justify-space-between fill-height"
    v-if="!loading"
  >
    <AboutHeader />
    <!--Licenses-->
    <div>
      <div>
        <BotPlaceholder
          class="custom--botito"
          type="about"
          :showText="false"
          justBot
          style="padding: 0 !important"
        />
      </div>
      <CardHome
        :item="card"
        class="mx-auto container-license"
        :sizes="{ font: '1.5rem', icon: '2.5rem' }"
        showText
        style="background-color: var(--v-background-accent) !important"
      />
    </div>
    <!--Expiration date-->
    <div class="text-center">
      <v-row no-gutters class="px-5" v-if="licenses.expirationDate">
        <v-col cols="12" class="pb-2">
          <span style="color: var(--text)">
            {{ $i18n.t("Licenses expire on") }}
          </span>
        </v-col>
        <v-col cols="12">
          <span class="primary--text" style="font-size: 24px; font-weight: 800">
            {{
              luxon()
                .fromSQL(licenses.expirationDate)
                .setLocale($i18n.locale)
                .toLocaleString(luxon().DATE_SHORT)
            }}
          </span>
        </v-col>
      </v-row>
    </div>
    <!--Footer-->
    <div style="text-align: center">
      <span
        class="font-weight-bold"
        style="cursor: pointer; font-size: 18px; color: var(--text)"
        @click="$emit('open:docs')"
      >
        uContact {{ `${$i18n.t("Version")} ${versions.current}` }}
      </span>
    </div>
  </div>
</template>
<script>
import CardHome from "@/components/home/Card";
import AboutHeader from "@/components/menu/about/AboutHeader";
import { luxon, inbox } from "@/utils";

export default {
  name: "AboutFull",

  components: {
    CardHome,
    AboutHeader,
  },

  props: {
    versions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      card: {
        value: "licenses",
        text: () => "Licenses",
        textLeft: "Total",
        textRight: "Available",
        total: () => this.licenses?.total || 0,
        left: () => this.licenses?.total || 0,
        right: () => this.licenses?.available || 0,
        icon: () => "mdi-sofa-single",
        color: () => "var(--v-primary-base)",
        style: { height: "20%" },
        invertCalc: true,
      },
      licenses: {
        available: 0,
        total: 0,
        expirationDate: "",
      },
      interval: null,
      loading: true,
      luxon,
    };
  },

  mounted() {
    this.getLicenses();
    this.startInterval();
  },

  beforeDestroy() {
    this.stopInterval();
  },

  methods: {
    startInterval() {
      this.interval = setInterval(() => {
        this.getLicenses();
      }, 5000);
    },

    stopInterval() {
      clearInterval(this.interval);
    },

    async getLicenses() {
      const data = await inbox().getLicensesInfo();
      this.licenses = data;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.container-license {
  min-height: 140px !important;
  max-height: 20%;
  min-width: fit-content;
  max-width: 60%;
  position: relative;
  top: -4%;
}
</style>
