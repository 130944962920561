var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ReactiveMenu',{ref:"reactiveMenu",attrs:{"hotkey":"alt+b"},on:{"hotkey":function($event){return _vm.openMenu()},"open":function($event){return _vm.focusElement()}},scopedSlots:_vm._u([{key:"activator",fn:function({ onMenu, attrs }){return [_c('v-badge',{attrs:{"overlap":"","left":"","bottom":"","offset-x":"15","offset-y":"20","color":"primary","value":_vm.useInboxStore.pause.status && _vm.useInboxStore.pause.status.name != ''
          ? true
          : false},scopedSlots:_vm._u([{key:"badge",fn:function(){return [(
            _vm.useInboxStore.pause.status &&
            _vm.useInboxStore.pause.status.name != ''
          )?_c('Timer',{attrs:{"start":_vm.useInboxStore.pause.status.start}}):_vm._e()]},proxy:true}],null,true)},[(
          _vm.useInboxStore.pause.status && _vm.useInboxStore.pause.status.name != ''
        )?_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onTooltip }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.inTime || _vm.useInboxStore.status === 'Already connected',"icon":""},on:{"dblclick":function($event){return _vm.setBreak(_vm.useInboxStore.pause.status)}}},'v-btn',attrs,false),{ ...onMenu, ...onTooltip }),[_c('v-icon',[_vm._v("mdi-play-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("Status")))])]):_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onTooltip }){return [_c('v-btn',_vm._g(_vm._b({ref:"btnStatus",attrs:{"icon":""}},'v-btn',attrs,false),{ ...onMenu, ...onTooltip }),[_c('v-icon',[_vm._v("mdi-coffee-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("Status")))])])],1)]}},{key:"content",fn:function(){return [_c('v-card',{staticClass:"shadow-md container-breaks",attrs:{"width":_vm.$vuetify.breakpoint.smAndDown ? '' : '370px',"elevation":"0"}},[_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":""}},[_c('v-toolbar',{staticClass:"d-flex justify-center container-toolbar",staticStyle:{"position":"relative"},style:({
            background: _vm.colorPerTheme(),
          }),attrs:{"flat":"","tile":""}},[_c('div',{staticClass:"text-center",staticStyle:{"margin":"auto"}},[(!!_vm.useInboxStore.pause.status.name)?_c('v-btn',{ref:"btnSetAsActive",attrs:{"rounded":"","color":"primary"},on:{"click":function($event){return _vm.setBreak(_vm.useInboxStore.pause.status)}}},[_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$i18n.t("Set as active"))+" ")]),_c('div',[_c('v-icon',{staticClass:"mb-0 ml-2"},[_vm._v(_vm._s("mdi-play-circle-outline"))])],1)]):_vm._e()],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{staticStyle:{"position":"absolute","right":"1.5rem"},attrs:{"icon":""},on:{"click":function($event){return _vm.openMenu()}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1):_vm._e()],1),(_vm.useInboxStore.pause.breaks.length && _vm.mode == 1)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"text-center justify-center",attrs:{"no-gutters":""}},[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.useInboxStore.pause.status != null),expression:"useInboxStore.pause.status != null"}],staticClass:"pa-3 fill-height",attrs:{"cols":"12"}},_vm._l((_vm.subBreaks),function(statuses,index){return _c('div',{key:index},[_c('div',{staticClass:"d-flex flex-wrap text-center justify-center px-0",attrs:{"no-gutters":""}},_vm._l((statuses),function(status,iJ){return _c('v-tooltip',{key:iJ,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-card',_vm._g({ref:"status",refInFor:true,staticClass:"rounded-xl pa-3 break-hover break--card",class:_vm.useInboxStore.pause.status.name &&
                          (status.name == _vm.useInboxStore.pause.status.name) !=
                            ''
                            ? 'active-background'
                            : 'background-hover',attrs:{"flat":"","outlined":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.setBreak(status);
                          _vm.$refs.reactiveMenu.closeMenu();},"click":function($event){return _vm.setBreak(status)}}},on),[_c('v-icon',{staticClass:"mb-2",attrs:{"color":status.color,"large":""}},[_vm._v(" "+_vm._s(status.icon)+" ")]),_c('div',{staticClass:"text-caption px-1",staticStyle:{"overflow-x":"hidden","white-space":"nowrap","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(_vm.defaultBreaks.includes(status.name) || status.name == "Unavailable" ? _vm.$i18n.t(status.name) : status.name)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.defaultBreaks.includes(status.name) ? _vm.$i18n.t(status.name) : status.name)+" ")])])}),1)])}),0),(
                _vm.useInboxStore.pause.status &&
                _vm.useInboxStore.pause.status.name != ''
              )?_c('v-col',{attrs:{"cols":"12"}}):_vm._e()],1)],1):_vm._e(),(!_vm.useInboxStore.pause.breaks.length)?_c('v-col',{attrs:{"cols":"12"}},[_c('BotPlaceholder',{attrs:{"type":"relax"}})],1):_vm._e()],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }