<template>
  <div
    class="d-flex justify-center align-center file-drop-area pa-4"
    @dragover.prevent="dragOver"
    @drop.prevent="drop"
    @paste.prevent="paste"
  >
    <v-row no-gutters style="position: relative; z-index: 3; max-width: 100%">
      <v-col cols="12" class="d-flex justify-center align-center">
        <v-icon color="primary" size="50" class="pr-4"
          >mdi-cloud-upload-outline</v-icon
        >
        <div class="d-flex flex-column">
          <span class="text-h6 text--secondary">
            {{ $i18n.t("Drop file here") }}
          </span>
          <span
            class="text-caption text--secondary text-decoration-underline"
            @click="$refs.fileInput.click()"
          >
            {{ $i18n.t("or click") }}
          </span>
        </div>
      </v-col>

      <!--Show accepted files-->
      <v-col
        cols="12"
        class="d-flex justify-center align-center py-2"
        v-if="$props.accept"
      >
        <span class="text-caption text--secondary">
          {{ $i18n.t("File type") }}: {{ accept }}
        </span>
      </v-col>
      <!--Show the name of the files and the size parsed -->
      <v-col cols="12" :class="$props.multiple ? 'overflow-auto' : ''">
        <div class="d-flex flex-row flex-nowrap" v-if="!$props.multiple">
          <div class="d-flex" style="width: 100%">
            <span
              class="text-caption text--secondary"
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ fileInfo($attrs.value) }}
            </span>
            <v-icon
              v-if="$attrs.value"
              color="error"
              @click="removeFile($attrs.value)"
              class="ml-2"
              small
            >
              mdi-close-circle
            </v-icon>
          </div>
        </div>
        <div
          class="d-flex flex-row flex-nowrap"
          style="width: fit-content"
          v-else
        >
          <v-chip
            style="max-width: 200px"
            v-for="(file, index) in $attrs.value"
            :key="index"
            class="ma-1"
            close
            @click:close="removeFile(file)"
            :disabled="disabled"
          >
            <span
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ fileInfo(file) }}
            </span>
          </v-chip>
        </div>
      </v-col>

      <input
        style="display: none"
        type="file"
        ref="fileInput"
        :accept="accept"
        :multiple="multiple"
        :disabled="disabled"
        @change="onChange"
        class="file-input"
      />
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ImportFile",
  props: {
    value: {
      type: [File, Array],
      default: null,
    },
    accept: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxFiles: {
      type: Number,
    },
    maxSize: {
      type: Number,
    },
  },
  mounted() {
    if (this.$props.multiple) {
      this.$attrs.value = [];
    } else {
      this.$attrs.value = null;
    }
  },
  methods: {
    onChange(e) {
      const files = e.target.files;
      this.loadFiles(files);
    },

    dragOver(e) {
      e.preventDefault();
      e.dataTransfer.dropEffect = "copy";
    },
    drop(e) {
      e.preventDefault();
      const files = this.$props.multiple
        ? e.dataTransfer.files
        : [e.dataTransfer.files[0]];
      this.loadFiles(files);
    },
    paste(e) {
      e.preventDefault();
      e.clipboardData.dropEffect = "copy";
      const files = this.$props.multiple
        ? e.clipboardData.files
        : [e.clipboardData.files[0]];
      this.loadFiles(files);
    },
    fileInfo(file) {
      if (file) {
        const size = file.size;
        const units = ["Bytes", "KB", "MB", "GB", "TB"];
        let i = Math.floor(Math.log(size) / Math.log(1024));

        // Asegúrate de que el índice no sea menor que cero o mayor que la cantidad de unidades disponibles
        i = Math.min(Math.max(0, i), units.length - 1);

        const fileSize = (size / Math.pow(1024, i)).toFixed(2);

        return `${file.name} (${fileSize} ${units[i]})`;
      }
    },
    loadFiles(files) {
      //allowedTypes can be empty, so we need to check if it is empty
      for (const file of files) {
        if (!this.$props.accept || this.$props.accept.includes(file.type)) {
          if (this.$props.multiple) {
            if (
              this.$props.maxFiles !== undefined &&
              this.$attrs.value.length >= this.$props.maxFiles
            ) {
              this.$notify({
                group: "app",
                text: "Max files reached",
                duration: 2000,
              });

              return;
            }
            if (
              this.$props.maxSize !== null &&
              file.size > this.$props.maxSize
            ) {
              this.$notify({
                group: "app",
                text: "Max file size reached",
                duration: 2000,
              });

              return;
            }

            this.$attrs.value.push(file);
          } else {
            if (
              this.$props.maxSize !== null &&
              file.size > this.$props.maxSize
            ) {
              this.$notify({
                group: "app",
                text: "Max file size reached",
                duration: 2000,
              });

              return;
            }

            this.$attrs.value = file;
          }
          this.$refs.fileInput.value = null; // Limpia el valor del input en caso de que se haya seleccionado un archivo previamente
          this.$emit("input", this.$attrs.value);
        } else {
          this.$notify({
            group: "app",
            text: "File type not allowed",
            duration: 2000,
          });
        }
      }
    },
    removeFile(file) {
      if (this.$props.multiple) {
        this.$attrs.value.splice(this.$attrs.value.indexOf(file), 1);
      } else {
        this.$attrs.value = null;
      }
      this.$emit("input", this.$attrs.value);
    },
  },
  watch: {
    value(newValue) {
      this.$attrs.value = newValue;
    },
  },
};
</script>

<style lang="scss">
.file-drop-area {
  position: relative;
  border-radius: 4px;
  width: 100%;
  &:before {
    border-radius: 4px;
    background: var(--v-primary-base);
    opacity: 0.08;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: "";
  }
}
</style>
