<template>
  <v-dialog
    v-model="dialog"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
    max-width="35%"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    width="auto"
  >
    <v-btn
      @click="$emit('close')"
      icon
      v-if="$vuetify.breakpoint.smAndDown"
      style="position: absolute; z-index: 1; right: 0"
    >
      <v-icon> mdi-close </v-icon>
    </v-btn>
    <v-card
      height="100%"
      class="d-flex flex-column justify-center about--card"
      :class="hasPermissions ? 'full-card' : 'simple-card'"
      color="var(--v-background-base)"
    >
      <v-card-text
        :style="{ flex: Number(!loading) }"
        class="overflow-y-auto pa-6 d-flex justify-center align-center fill-height overflow-x-hidden"
      >
        <template v-if="!loading">
          <AboutFull
            v-if="hasPermissions"
            @open:docs="openDocs"
            :versions="versions"
          />
          <AboutSimple v-else @open:docs="openDocs" :versions="versions" />
        </template>
        <Loader v-else />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { luxon, useAppStore, openDocs } from "@/utils";
import Configuration from "@/controllers/configuration";
import AboutFull from "@/components/menu/about/AboutFull.vue";
import AboutSimple from "@/components/menu/about/AboutSimple.vue";

export default {
  name: "AboutDialog",
  components: {
    AboutFull,
    AboutSimple,
  },
  data() {
    return {
      versions: {
        current: "",
        latest: "",
      },
      loading: true,
      luxon,
      dialog: true,
    };
  },

  mounted() {
    this.getVersion();
  },

  methods: {
    async getVersion() {
      const data = await Configuration.getVersion();
      this.versions = data;
      this.loading = false;
    },

    openDocs() {
      openDocs(`changelog/changelogDetail.html#${this.versions.current}`);
    },
  },

  computed: {
    hasPermissions() {
      return (
        useAppStore().user.superuser || useAppStore().profile.controllers.length
      );
    },
  },
};
</script>

<style lang="scss">
.custom--botito {
  display: flex;
  justify-content: center;

  svg {
    height: 60%;
    max-width: 40%;
    position: relative;
    z-index: 1;
  }
}

.v-dialog:has(.about--card.full-card) {
  aspect-ratio: 300 / 500;
  min-height: 75%;
}

.v-dialog:has(.about--card.simple-card) {
  aspect-ratio: 1;
  min-height: 50%;
}

.about--card {
  transition: all 0.3s;
}
</style>
